import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { MenuContext, RestaurantContext } from '../constants/contexts'
import { listenToDishesByOrderId } from '../database/reads/dishes'
import { deleteOrder, updateOrderStatus } from '../database/writes/orders'
import {
  Dish,
  DishModification,
  MenuItem,
  Order,
  Table as TableType,
} from '../types'
import { toDollar } from '../utils/money'
import ConfirmActionButton from './ConfirmActionButton'
import StatusBadge from './StatusBadge'

interface DishWrapper {
  dish: Dish
  menuItem: MenuItem
}

export default function OrderCard({
  order,
  table,
}: {
  order: Order
  table: TableType
}) {
  const toast = useToast()

  const [dishes, setDishes] = useState<Dish[]>([])
  const { status } = order

  const restaurant = useContext(RestaurantContext)
  const menu = useContext(MenuContext)

  const dishWrappers: DishWrapper[] = useMemo(() => {
    if (!menu.length) return []
    const dishWrapperArr: DishWrapper[] = []
    dishes.forEach((dish) => {
      const menuItem = menu.find((item) => item.id === dish.itemId)
      if (menuItem)
        dishWrapperArr.push({
          dish,
          menuItem,
        })
    })
    return dishWrapperArr
  }, [menu, dishes])

  useEffect(() => {
    if (!restaurant) return
    return listenToDishesByOrderId({
      restaurantId: restaurant.id,
      tableId: order.tableId,
      orderId: order.id,
      cb: setDishes,
    })
  }, [restaurant, order])

  const onStepBack = async () => {
    if (!restaurant) return
    let prevStatus = ''
    switch (status) {
      case 'In Progress':
        prevStatus = 'New'
        break
      case 'Served':
        prevStatus = 'In Progress'
        break
      case 'Paid':
        prevStatus = 'Served'
        break
      default:
        console.error(
          `Error: unable to find previous status, order status = ${status}`
        )
        return
    }
    await updateOrderStatus({
      restaurantId: restaurant.id,
      tableId: table.id,
      orderId: order.id,
      status: prevStatus,
    })
    toast({
      title: `Order marked as ${prevStatus.toLowerCase()}`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }

  const onStepForward = async () => {
    if (!restaurant) return
    let nextStatus = ''
    switch (status) {
      case 'New':
        nextStatus = 'In Progress'
        break
      case 'In Progress':
        nextStatus = 'Served'
        break
      case 'Served':
        nextStatus = 'Paid'
        break
      default:
        console.error(
          `Error: unable to find next status, order status = ${status}`
        )
    }
    await updateOrderStatus({
      restaurantId: restaurant.id,
      tableId: table.id,
      orderId: order.id,
      status: nextStatus,
    })
    toast({
      title: `Order marked as ${nextStatus.toLowerCase()}`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }

  const handleReject = () => {
    if (!restaurant) return
    deleteOrder({
      restaurantId: restaurant.id,
      tableId: table.id,
      orderId: order.id,
    })
  }

  return (
    <AccordionItem>
      <AccordionButton>
        <HStack align='center' width='100%'>
          <Text fontWeight='bold'>
            Table{' '}
            {table.tableNumber > 9
              ? table.tableNumber
              : '0' + table.tableNumber}
          </Text>
          <Text>/</Text>

          <Text>{order.id.slice(-3)}</Text>
          <StatusBadge status={order.status} />
          <Spacer />
          <Text>{toDollar(order.subtotal + order.tax)}</Text>
          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <AccordionPanel px={8}>
        <TableContainer pb={4}>
          <Table variant='simple' colorScheme='whiteAlpha'>
            <Thead>
              <Tr>
                <Th isNumeric width='10%'>
                  QTD
                </Th>
                <Th>Dish</Th>
                <Th isNumeric>Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dishWrappers.map(({ menuItem, dish }: DishWrapper) => (
                <Tr key={dish.id}>
                  <Td isNumeric width='10%'>
                    {dish.quantity}
                  </Td>
                  <Td>
                    <Heading size='sm'>{menuItem.name}</Heading>
                    {dish.modifications.map(
                      (modification: DishModification, index: number) => {
                        return (
                          <Text key={index}>
                            {modification.title}:{' '}
                            {modification.modifications
                              .filter((mod) => mod.quantity > 0)
                              .map((mod, i, arr) => {
                                if (
                                  mod.quantity > 1 ||
                                  modification.type === 'quantity'
                                ) {
                                  return (
                                    mod.name +
                                    ' x' +
                                    mod.quantity +
                                    (i === arr.length - 1 ? '' : ', ')
                                  )
                                } else {
                                  return (
                                    mod.name +
                                    (i === arr.length - 1 ? '' : ', ')
                                  )
                                }
                              })}
                          </Text>
                        )
                      }
                    )}
                  </Td>
                  <Td isNumeric>{toDollar(dish.price * dish.quantity)}</Td>
                </Tr>
              ))}
              <Tr>
                <Td isNumeric width='10%' />
                <Td>
                  <Heading size='sm'>Tax</Heading>
                </Td>
                <Td isNumeric>{toDollar(order.tax)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <HStack justify='right' width='100%'>
          <ButtonGroup
            spacing='2'
            isAttached
            variant='outline'
            colorScheme='background'
          >
            {status === 'New' ? (
              <ConfirmActionButton
                title='Reject Order'
                action='Reject'
                handleConfirm={handleReject}
                leftIcon={<ArrowBackIcon />}
              />
            ) : (
              <Button leftIcon={<ArrowBackIcon />} onClick={onStepBack}>
                {status === 'In Progress' && 'New'}
                {status === 'Served' && 'In Progress'}
                {status === 'Paid' && 'Served'}
              </Button>
            )}
            {status !== 'Paid' && (
              <Button
                rightIcon={<ArrowForwardIcon />}
                variant='solid'
                bg='brand.400'
                color='shade.white'
                _hover={{
                  bg: 'brand.600',
                }}
                onClick={onStepForward}
              >
                {status === 'New' && 'In Progress'}
                {status === 'In Progress' && 'Served'}
                {status === 'Served' && 'Paid'}
              </Button>
            )}
          </ButtonGroup>
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  )
}
