import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  GoogleAuthProvider,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
} from 'firebase/auth'
import { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router'
import { auth, getActionCodeSettings } from '../constants/firebase'

const provider = new GoogleAuthProvider()

function Logo() {
  return (
    <svg height={32} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'>
      <rect fill='#e74536' width='128' height='128' rx='12' ry='12' />
      <path
        fill='#fff'
        d='M53.47,48.27v51.31h-21.41V27.6h27.9c12.23,0,21.81,2.78,28.74,8.33,8.23,6.64,12.34,15.66,12.34,27.05s-3.52,19.79-10.55,26.68c-7.03,6.89-16.08,10.34-27.16,10.34-1.3,0-3.43-.07-6.38-.21v-21.15h3.43c12.48,0,18.72-5.22,18.72-15.66,0-9.81-6.14-14.71-18.4-14.71h-7.22Z'
      />
    </svg>
  )
}

export default function Login() {
  const navigate = useNavigate()
  const toast = useToast()
  const [email, setEmail] = useState('')
  const restaurantId = sessionStorage.getItem('restaurantId')

  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem('emailForSignIn')
    while (!email) {
      email = window.prompt('Please provide your email for confirmation')
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((_) => {
        toast({
          title: 'Sign In Success',
          description: 'Successfully signed into your account',
          status: 'success',
          isClosable: true,
          position: 'top',
        })
        navigate('/enter')
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.error(`Error ${errorCode}: ${errorMessage}`)
      })
  }

  // if already logged in, navigate to home
  onAuthStateChanged(auth, (authUser) => {
    if (!authUser) return
    if (!restaurantId) navigate('/enter')
    navigate('/')
  })

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((_) => {
        toast({
          title: 'Sign In Success',
          description: 'Successfully signed into your account',
          status: 'success',
          isClosable: true,
          position: 'top',
        })
        navigate('/enter')
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        console.error(`Error ${errorCode}: ${errorMessage}`)
      })
  }

  const signInWithEmail = () => {
    sendSignInLinkToEmail(
      auth,
      email,
      getActionCodeSettings(
        `${window.location.protocol}//${window.location.host}`
      )
    )
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email)
        toast({
          title: 'Verification Email Sent',
          description: 'Please verify your email to finish sign in',
          status: 'success',
          isClosable: true,
          position: 'top',
        })
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.error(`Error ${errorCode}: ${errorMessage}`)
      })
  }

  return (
    <Flex w='100%' h='100vh' bg='shade.white' justify='center' align='center'>
      <Flex maxW='1024' h='100%' maxH='736' justify='center' align='center'>
        <Card
          h='100%'
          flex={9}
          variant='filled'
          bg='brand.500'
          color='shade.white'
          rounded='xl'
          py={20}
          px={12}
          mr={8}
        >
          <Heading size='md'>DANQR</Heading>
          <Heading size='lg' mt={20} mb={4}>
            Welcome to the world's most reliable RMS.
          </Heading>
          <Text size='md'>
            DanQR is your restaurant's best friend and number one supporter.
          </Text>
        </Card>
        <Card bg='white' variant='unstyled' p={8} flex={12}>
          <CardHeader>
            <Logo />
            <Heading size='md' mt={8}>
              Login
            </Heading>
            <Text size='md' color='gray.500' mb={4}>
              Starting service? Good luck. We're here for you.
            </Text>
          </CardHeader>

          <CardBody>
            <Stack align='center' minW={400} spacing={4}>
              <Button
                aria-label='Sign in with Google'
                variant='outline'
                w='100%'
                size='lg'
                leftIcon={<FcGoogle />}
                onClick={signInWithGoogle}
                colorScheme='gray'
                borderRadius='3xl'
              >
                Sign in with Google
              </Button>
              <HStack w='100%' align='center'>
                <Divider />
                <Text whiteSpace='nowrap' color='gray.500' size='xs'>
                  or Sign in with Email
                </Text>
                <Divider />
              </HStack>
              <FormControl>
                <FormLabel mb={3}>Email</FormLabel>
                <InputGroup size='md'>
                  <Input
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    variant='outline'
                    borderRadius='3xl'
                    placeholder='your.email@email.com'
                    px={5}
                  />
                </InputGroup>
              </FormControl>
              <Button
                size='md'
                w='100%'
                color='white'
                bg='#e74536'
                onClick={signInWithEmail}
                borderRadius='3xl'
              >
                Login
              </Button>
              <span>
                Not registered yet? Visit our{' '}
                <a href='https://www.danqr.com/' style={{ color: '#e74536' }}>
                  business site
                </a>{' '}
                to get started.
              </span>
            </Stack>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  )
}
