import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../constants/firebase'

export async function addTable({
  restaurantId,
  tableNumber,
}: {
  restaurantId: string
  tableNumber: number
}) {
  const tablesRef = collection(db, 'Restaurants', restaurantId, 'Tables')
  try {
    return await addDoc(tablesRef, {
      tableNumber,
    })
  } catch (err) {
    console.error(err)
  }
}

export async function deleteTable({
  restaurantId,
  id,
}: {
  restaurantId: string
  id: string
}) {
  const tableRef = doc(db, 'Restaurants', restaurantId, 'Tables', id)
  try {
    return await deleteDoc(tableRef)
  } catch (err) {
    console.error(err)
  }
}
