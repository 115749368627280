import {
  collectionGroup,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where
} from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Order } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToOrdersByRestaurantId({
  restaurantId,
  cb,
}: {
  restaurantId: string
  cb: Function
}) {
  const orderRef = collectionGroup(db, 'Orders')
  return onSnapshot(
    query(
      orderRef,
      where('restaurantId', '==', restaurantId),
      where(
        'dateTime',
        '>=',
        Timestamp.fromDate(new Date(new Date().valueOf() - 1000 * 60 * 60 * 12)) // 1000ms * 60s * 60min * 12hrs
      ),
      orderBy('dateTime')
    ).withConverter(genericConverter<Order>()),
    (orderSnapshot) => {
      cb(orderSnapshot.docs.map((doc) => doc.data()))
    },
    (err) => console.error(err)
  )
}

export function getOrdersByTerm({
  restaurantId,
  startDate,
  endDate,
  cb,
}: {
  restaurantId: string
  startDate: Date
  endDate: Date
  cb: Function
}) {
  const orderRef = collectionGroup(db, 'Orders')
  return onSnapshot(
    query(
      orderRef,
      where('restaurantId', '==', restaurantId),
      where('dateTime', '>=', Timestamp.fromDate(startDate)),
      where('dateTime', '<=', Timestamp.fromDate(endDate)),
      orderBy('dateTime')
    ).withConverter(genericConverter<Order>()),
    (orderSnapshot) => {
      cb(orderSnapshot.docs.map((doc) => doc.data()))
    },
    (err) => console.error(err)
  )
}
