import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import {
  AiOutlineCheck,
  AiOutlineEdit,
  AiOutlineUserAdd,
  AiOutlineUserDelete,
} from 'react-icons/ai'
import { UserContext } from '../../constants/contexts'
import { listenToEmployeesByRestaurantId } from '../../database/reads/employees'
import { getUserByEmail } from '../../database/reads/users'
import {
  addEmployee,
  deleteEmployee,
  updateEmployee,
} from '../../database/writes/employees'
import { AdminStatus, Employee, Restaurant } from '../../types'
import ConfirmActionButton from '../ConfirmActionButton'

function EmployeeRow({
  employee,
  restaurant,
}: {
  employee: Employee
  restaurant: Restaurant
}) {
  const { id, status, isInvited, email } = employee
  const user = useContext(UserContext)

  const [isEdit, setIsEdit] = useState(false)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [newStatus, setNewStatus] = useState<AdminStatus>(status)

  return (
    <Tr key={id}>
      <Td>{email}</Td>
      <Td>
        {isEdit ? (
          <Select
            placeholder='Select status'
            value={newStatus}
            onChange={(e) => {
              if (e.currentTarget.value)
                setNewStatus(e.currentTarget.value as AdminStatus)
            }}
          >
            <option value={AdminStatus.admin}>Admin</option>
            <option value={AdminStatus.manager}>Manager</option>
            <option value={AdminStatus.employee}>Employee</option>
          </Select>
        ) : (
          <HStack>
            <Badge>{status}</Badge>
            {isInvited && <Badge>invited</Badge>}
          </HStack>
        )}
      </Td>
      <Td isNumeric>
        {isEdit ? (
          <IconButton
            aria-label='Confirm edit'
            icon={<Icon as={AiOutlineCheck} />}
            variant='outline'
            colorScheme='green'
            mr={2}
            onClick={() => {
              updateEmployee({
                restaurantId: restaurant.id,
                userId: id,
                status: newStatus,
              })
              setIsEdit(false)
            }}
          />
        ) : (
          <IconButton
            isDisabled={id === user?.id}
            aria-label='Edit employee'
            icon={<Icon as={AiOutlineEdit} />}
            variant='outline'
            mr={2}
            onClick={() => {
              setIsEdit(true)
            }}
          />
        )}
        <ConfirmActionButton
          isDisabled={id === user?.id}
          title={`Remove ${email}`}
          action={`Remove`}
          description={`Are you sure you want to remove ${email}?`}
          aria-label='Remove employee'
          icon={<Icon as={AiOutlineUserDelete} />}
          variant='outline'
          colorScheme='brand'
          isIcon
          isLoading={isBtnLoading}
          handleConfirm={() => {
            setIsBtnLoading(true)
            deleteEmployee({
              restaurantId: restaurant.id,
              userId: id,
            }).finally(() => setIsBtnLoading(false))
          }}
        />
      </Td>
    </Tr>
  )
}

function EmployeesCardBody({ restaurant }: { restaurant: Restaurant }) {
  const toast = useToast()

  const [employees, setEmployees] = useState<Employee[]>([])
  const [status, setStatus] = useState<AdminStatus>()
  const [email, setEmail] = useState('')

  useEffect(() => {
    return listenToEmployeesByRestaurantId({
      restaurantId: restaurant.id,
      cb: setEmployees,
    })
  }, [restaurant.id])

  return (
    <Flex flexDirection='column' gap='20px'>
      <TableContainer>
        <Table variant='simple' colorScheme='brand'>
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Status</Th>
              <Th isNumeric>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {employees.map((employee) => (
              <EmployeeRow
                key={employee.id}
                employee={employee}
                restaurant={restaurant}
              />
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>
                <Input
                  placeholder='Enter employee email'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value)
                  }}
                />
              </Th>
              <Th>
                <Select
                  placeholder='Select status'
                  onChange={(e) => {
                    if (e.currentTarget.value)
                      setStatus(e.currentTarget.value as AdminStatus)
                  }}
                >
                  <option
                    selected={status === AdminStatus.admin}
                    value={AdminStatus.admin}
                  >
                    Admin
                  </option>
                  <option
                    selected={status === AdminStatus.manager}
                    value={AdminStatus.manager}
                  >
                    Manager
                  </option>
                  <option
                    selected={status === AdminStatus.employee}
                    value={AdminStatus.employee}
                  >
                    Employee
                  </option>
                </Select>
              </Th>
              <Th isNumeric>
                <IconButton
                  aria-label='Add employee'
                  icon={<Icon as={AiOutlineUserAdd} />}
                  variant='outline'
                  colorScheme='green'
                  onClick={async () => {
                    if (!status) {
                      toast({
                        title: `Status not selected.`,
                        description: 'Please select a status',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top',
                      })
                      return
                    }
                    const user = await getUserByEmail({ email })
                    if (!user) {
                      toast({
                        title: `User ${email} does not exist.`,
                        description: 'Please ask them to make an account first',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top',
                      })
                      return
                    }
                    addEmployee({
                      restaurantId: restaurant.id,
                      userId: user.id,
                      status,
                      isInvited: true,
                      email,
                    })
                    setStatus(undefined)
                    setEmail('')
                  }}
                />
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Flex>
  )
}

export default function EmployeesCard({
  restaurant,
}: {
  restaurant: Restaurant
}) {
  return (
    <Card variant='ghost' w='100%'>
      <CardHeader pt='0'>
        <Flex justify='space-between' align='center'>
          <Heading as='h2' size='lg' my={2}>
            Employees
          </Heading>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody>
        <EmployeesCardBody restaurant={restaurant} />
      </CardBody>
    </Card>
  )
}
