import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore'
import { isUndefined } from 'lodash'
import { db } from '../../constants/firebase'
import { AdminStatus, Employee } from '../../types'
import { addUserRestaurant, removeUserRestaurant } from './users'

export async function addEmployee({
  restaurantId,
  userId,
  status,
  isInvited,
  email,
}: {
  restaurantId: string
  userId: string
  status: AdminStatus
  isInvited?: boolean
  email: string
}) {
  const employeeRef = doc(db, 'Restaurants', restaurantId, 'Employees', userId)

  try {
    await addUserRestaurant({
      restaurantId,
      userId,
    })
    return setDoc(employeeRef, {
      status,
      isInvited: !!isInvited,
      email,
    })
  } catch (err) {
    console.error(err)
  }
}

export async function updateEmployee({
  restaurantId,
  userId,
  isInvited,
  status,
}: {
  restaurantId: string
  userId: string
  isInvited?: boolean
  status?: AdminStatus
}) {
  const employeeRef = doc(db, 'Restaurants', restaurantId, 'Employees', userId)
  let update: Partial<Employee> = {}
  if (!isUndefined(isInvited)) update.isInvited = isInvited
  if (!isUndefined(status)) update.status = status
  try {
    return updateDoc(employeeRef, update)
  } catch (err) {
    console.error(err)
  }
}

export async function deleteEmployee({
  restaurantId,
  userId,
}: {
  restaurantId: string
  userId: string
}) {
  const employeeRef = doc(db, 'Restaurants', restaurantId, 'Employees', userId)
  removeUserRestaurant({ restaurantId, userId })
  try {
    return deleteDoc(employeeRef)
  } catch (err) {
    console.error(err)
  }
}
