import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { isUndefined } from 'lodash'
import { useContext, useState } from 'react'
import { ModContext, RestaurantContext } from '../../constants/contexts'
import {
  deleteImage,
  getImageURL,
  uploadImage,
} from '../../database/storage/images'
import { addMenuItem, updateMenuItem } from '../../database/writes/menu'
import { MenuItem, Modification } from '../../types'
import { toCents } from '../../utils/money'
import ModCard from './ModCard'
import ModModal from './ModModal'

export default function MenuItemModal({
  item,
  isOpen,
  onClose,
}: {
  item?: MenuItem
  isOpen: boolean
  onClose: () => void
}) {
  const { isOpen: isModCreateOpen, onClose: onModCreateClose } = useDisclosure()
  const toast = useToast()
  const restaurant = useContext(RestaurantContext)
  const mods = useContext(ModContext)

  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [isOverrideTax, setIsOverrideTax] = useState(!isUndefined(item?.tax))

  const [name, setName] = useState(item?.name ?? '')
  const [picture, setPicture] = useState<File>()
  const [description, setDescription] = useState(item?.description ?? '')
  const [basePrice, setBasePrice] = useState(item?.basePrice ?? 0)
  const [category, setCategory] = useState(item?.category ?? '')
  const [modifications, setModifications] = useState<string[]>(
    item?.modifications ?? []
  )
  const [tax, setTax] = useState(item?.tax ?? undefined)

  const onSubmit = async () => {
    setIsBtnLoading(true)
    if (!restaurant) return setIsBtnLoading(false)
    if (!name || !description || !category) {
      toast({
        title: 'Please fill in all required fields',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
      return setIsBtnLoading(false)
    }

    if (item) {
      const fields: Partial<MenuItem> = {
        name,
        description,
        basePrice,
        category,
        modifications,
      }
      if (isOverrideTax && !isUndefined(tax)) {
        fields.tax = tax
      }
      if (picture) {
        if (item.image) {
          await deleteImage({
            restaurantId: restaurant.id,
            imageId: item.id,
          })
        }
        const imageId = await uploadImage({
          restaurantId: restaurant.id,
          image: picture,
          id: item.id,
        })
        const imageURL = await getImageURL({
          restaurantId: restaurant.id,
          imageId,
        })
        fields.image = imageURL
      }
      await updateMenuItem({
        restaurantId: restaurant.id,
        menuItemId: item.id,
        ...fields,
      })
    } else {
      await addMenuItem({
        restaurantId: restaurant.id,
        name,
        description,
        picture,
        basePrice,
        category,
        modifications,
        tax: isOverrideTax && !isUndefined(tax) ? tax : undefined,
      })
    }

    setIsBtnLoading(false)
    toast({
      title: `Successfully ${item ? 'updated' : 'created'} menu item`,
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    })

    onClose()

    if (!item) {
      setName('')
      setPicture(undefined)
      setDescription('')
      setBasePrice(0)
      setCategory('')
      setModifications([])
      setTax(undefined)
      setIsOverrideTax(false)
    }
  }

  const handleCancel = () => {
    onClose()

    if (!item) {
      setName('')
      setPicture(undefined)
      setDescription('')
      setBasePrice(0)
      setCategory('')
      setModifications([])
      setTax(undefined)
      setIsOverrideTax(false)
    } else {
      setName(item.name)
      setDescription(item.description)
      setBasePrice(item.basePrice)
      setCategory(item.category)
      setModifications(item.modifications)
      setTax(item.tax)
      setIsOverrideTax(!isUndefined(item.tax))
    }
  }

  const handleSelect = (item: Modification) => {
    setModifications(
      modifications.some((modId) => modId === item.id)
        ? modifications.filter((modId) => modId !== item.id)
        : [...modifications, item.id]
    )
  }

  return (
    <>
      <Modal size='xl' isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent p='20px'>
          <ModalHeader>{item ? 'Edit' : 'Create'} Menu Item</ModalHeader>

          <ModalBody>
            <Tabs variant='soft-rounded'>
              <TabList>
                <Tab>Details</Tab>
                <Tab>Modifications</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <FormControl
                    display='grid'
                    gridTemplateColumns='1fr 2fr'
                    alignItems='center'
                    gap={2}
                  >
                    <FormLabel>Name:</FormLabel>
                    <Input
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                    <FormLabel>Description:</FormLabel>
                    <Input
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value)
                      }}
                    />
                    <FormLabel>Price:</FormLabel>
                    <NumberInput
                      value={basePrice / 100}
                      onChange={(_, value) => {
                        setBasePrice(toCents(value))
                      }}
                      min={0}
                      precision={2}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>

                    <FormLabel>Category:</FormLabel>
                    <Input
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                    />
                    <FormLabel>Picture:</FormLabel>
                    <input
                      type='file'
                      multiple={false}
                      onChange={(e) => {
                        setPicture(
                          e.target.files ? e.target.files[0] : undefined
                        )
                      }}
                      accept='.jpg,.png,.jpeg'
                    />
                    <Stack pt={4}>
                      <Checkbox
                        defaultChecked={isOverrideTax}
                        checked={isOverrideTax}
                        onChange={(e) =>
                          setIsOverrideTax(e.currentTarget.checked)
                        }
                        whiteSpace='nowrap'
                      >
                        Override default tax rate
                      </Checkbox>
                    </Stack>
                    <Box />
                    {isOverrideTax && (
                      <>
                        <FormLabel>Tax:</FormLabel>
                        <InputGroup>
                          <Input
                            value={isUndefined(tax) || isNaN(tax) ? '' : tax}
                            type='number'
                            onChange={(e) =>
                              setTax(e.currentTarget.valueAsNumber)
                            }
                          />
                          <InputRightAddon children='%' />
                        </InputGroup>
                      </>
                    )}
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <Stack>
                    {mods.map((item) => {
                      if (restaurant) {
                        return (
                          <ModCard
                            item={item}
                            restaurant={restaurant}
                            key={item.id}
                            onlySelect={true}
                            isChecked={modifications.some(
                              (modId) => modId === item.id
                            )}
                            selectFunction={handleSelect}
                          />
                        )
                      }
                      return <></>
                    })}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button
              bg='brand.400'
              color='shade.white'
              _hover={{
                bg: 'brand.600',
              }}
              mr={3}
              onClick={onSubmit}
              isLoading={isBtnLoading}
            >
              {item ? 'Confirm' : 'Create'}
            </Button>
            <Button variant='ghost' onClick={handleCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ModModal isOpen={isModCreateOpen} onClose={onModCreateClose} />
    </>
  )
}
