import { Spacer, Stack } from '@chakra-ui/layout'
import {
  Button,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react'
import { signOut } from 'firebase/auth'
import { useContext } from 'react'
import { MdLogout } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { RestaurantContext } from '../constants/contexts'
import { auth } from '../constants/firebase'
import MenuStack from './MenuStack'

export default function NavBar({ onlyLogOut }: { onlyLogOut?: boolean }) {
  const navigate = useNavigate()
  const toast = useToast()
  const isExpanded = true /* @TODO change to localStorage hook */
  const restaurant = useContext(RestaurantContext)

  return (
    <Stack h='100vh' p={2} bg='background.500' px={isExpanded ? 4 : 2} minW={isExpanded ? '175px' : '0'}>
      {restaurant && isExpanded && (
        <Image
          alt={restaurant.name}
          src={restaurant.logo}
          boxSize={20}
          borderRadius='full'
          objectFit='contain'
          border='2px solid white'
          bg='shade.white'
          alignSelf='center'
          m={6}
        />
      )}
      {!onlyLogOut && <MenuStack isExpanded={isExpanded} />}
      <Spacer />
      <Menu placement='right-end'>
        {!onlyLogOut ? (isExpanded ? (
          <MenuButton
            as={Button}
            leftIcon={<Icon as={MdLogout} boxSize={6} />}
            aria-label='Sign out options'
            color='shade.white'
            variant='ghost'
            _hover={{ bg: 'brand.200' }}
            _expanded={{ bg: 'brand.200' }}
            size='md'
            w='100%'
            justifyContent='start'
          >
            Sign Out
          </MenuButton>
          ) : (
          <MenuButton
            as={IconButton}
            icon={<Icon as={MdLogout} boxSize={6} />}
            aria-label='Sign out options'
            color='shade.white'
            variant='ghost'
            _hover={{ bg: 'brand.200' }}
            _expanded={{ bg: 'brand.200' }}
            size='lg'
          />
        )) : (isExpanded ? (
          <Button
            size='md'
            leftIcon={<Icon as={MdLogout} boxSize={6} />}
            aria-label='Sign out options'
            variant='ghost'
            _hover={{ bg: 'brand.200' }}
            color='shade.white'
            onClick={() => {
              signOut(auth)
                .then(() => {
                  toast({
                    title: 'Sign Out Success',
                    description: 'Successfully signed out of your account',
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                  })
                  sessionStorage.removeItem('restaurantId')
                  navigate('/login')
                })
                .catch((error) => {
                  console.error(error)
                })
            }}
            w='100%'
            justifyContent='start'
          >
            Sign Out
          </Button>
          ) : (
          <IconButton
            size='lg'
            icon={<Icon as={MdLogout} boxSize={6} />}
            aria-label='Sign out options'
            variant='ghost'
            _hover={{ bg: 'brand.200' }}
            color='shade.white'
            onClick={() => {
              signOut(auth)
                .then(() => {
                  toast({
                    title: 'Sign Out Success',
                    description: 'Successfully signed out of your account',
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                  })
                  sessionStorage.removeItem('restaurantId')
                  navigate('/login')
                })
                .catch((error) => {
                  console.error(error)
                })
            }}
          />
        ))}

        <MenuList bg='background.500'>
          {!onlyLogOut ? (<MenuItem
            color='shade.white'
            bg='background.500'
            _hover={{ bg: 'brand.200' }}
            onClick={() => {
              sessionStorage.removeItem('restaurantId')
              navigate('/enter')
            }}
          >
            Exit restaurant
          </MenuItem>) : <></>}
          <MenuItem
            color='shade.white'
            bg='background.500'
            _hover={{ bg: 'brand.200' }}
            onClick={() => {
              signOut(auth)
                .then(() => {
                  toast({
                    title: 'Sign Out Success',
                    description: 'Successfully signed out of your account',
                    status: 'success',
                    isClosable: true,
                    position: 'top',
                  })
                  sessionStorage.removeItem('restaurantId')
                  navigate('/login')
                })
                .catch((error) => {
                  console.error(error)
                })
            }}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  )
}
