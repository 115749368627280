import { SmallCloseIcon } from '@chakra-ui/icons'
import {
  Accordion,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Link,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { sum } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import NavBar from '../components/NavBar'
import OrderCard from '../components/OrderCard'
import TableCard from '../components/TableCard'
import { RestaurantContext } from '../constants/contexts'
import { CLIENT_SITE } from '../constants/links'
import { listenToOrdersByRestaurantId } from '../database/reads/orders'
import { listenToTablesByRestaurantId } from '../database/reads/tables'
import { Order, Table } from '../types'
import { toDollar } from '../utils/money'

export default function Home() {
  const ordersRef = useRef<HTMLDivElement>(null)
  //const gridRef = useRef<HTMLDivElement>(null);

  const [selectedTable, setSelectedTable] = useState<Table>()
  const [tables, setTables] = useState<Table[]>([])
  const [orders, setOrders] = useState<Order[]>([])
  //const [width, setWidth] = useState(0);

  const restaurant = useContext(RestaurantContext)

  /*useEffect(() => {
    function handleResize() {
      if (gridRef && gridRef.current) {
        setWidth(gridRef.current.offsetWidth);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);*/

  useEffect(() => {
    if (!restaurant) {
      return
    }
    return listenToTablesByRestaurantId({
      restaurantId: restaurant.id,
      cb: setTables,
    })
  }, [restaurant])

  useEffect(() => {
    if (!restaurant) {
      return
    }
    return listenToOrdersByRestaurantId({
      restaurantId: restaurant.id,
      cb: setOrders,
    })
  }, [restaurant])

  const scrollToTopOrders = () => {
    ordersRef.current?.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Flex flexDir='row' h='100vh' bg='background.50'>
      <NavBar />
      <Flex flex={1}>
        <Box flex={6} overflow='auto' position='relative'>
          <HStack justify='space-between'>
            <Heading as='h1' size='lg' m='20px' ml='40px'>
              Tables
            </Heading>
            <HStack spacing={1}>
              <Tag size='md' variant='subtle' colorScheme='red'>
                <TagLabel>
                  {orders.filter((order) => order.status === 'New').length} New
                </TagLabel>
              </Tag>
              <Tag size='md' variant='subtle' colorScheme='yellow'>
                <TagLabel>
                  {
                    orders.filter((order) => order.status === 'In Progress')
                      .length
                  }{' '}
                  In Progress
                </TagLabel>
              </Tag>
              <Tag size='md' variant='subtle' colorScheme='green'>
                <TagLabel>
                  {orders.filter((order) => order.status === 'Served').length}{' '}
                  Served
                </TagLabel>
              </Tag>
            </HStack>
          </HStack>

          <Grid
            templateColumns='repeat(auto-fill, minmax(200px, 1fr))'
            borderRadius='xl'
            overflow='hidden'
            margin='40px'
            mt='0px'
            gap='3px'
          >
            {tables.map((table) => (
              <TableCard
                key={table.id}
                table={table}
                isSelected={selectedTable?.id === table.id}
                orders={orders.filter((order) => order.tableId === table.id)}
                handleClick={() => {
                  setSelectedTable(
                    selectedTable?.id === table.id ? undefined : table
                  )
                  scrollToTopOrders()
                }}
              />
            ))}
          </Grid>
        </Box>
        <Card
          m={4}
          flex={4}
          overflow='auto'
          rounded='3xl'
          bg='shade.white'
          ref={ordersRef}
        >
          {selectedTable ? (
            <Stack py={4} spacing={4} height='full'>
              <Stack px={4} spacing={4} flex={1}>
                <HStack alignItems='center' spacing={4}>
                  <Heading size='md'>
                    Table{' '}
                    {selectedTable.tableNumber > 9
                      ? selectedTable.tableNumber
                      : '0' + selectedTable.tableNumber}
                  </Heading>
                  <Link
                    href={`${CLIENT_SITE}/${restaurant?.id}/${selectedTable.id}`}
                    target='_blank'
                  >
                    <Button
                      alignSelf='start'
                      variant='outline'
                      color='brand.400'
                      borderColor='brand.400'
                    >
                      Add order
                    </Button>
                  </Link>
                  <Spacer />
                  <IconButton
                    aria-label='View all orders'
                    icon={<SmallCloseIcon />}
                    colorScheme='gray'
                    variant='solid'
                    isRound
                    onClick={() => setSelectedTable(undefined)}
                  />
                </HStack>
                <Accordion allowMultiple>
                  {orders
                    .filter(
                      (order) =>
                        order.tableId === selectedTable.id &&
                        order.status !== 'Paid'
                    )
                    .map((order: Order) => {
                      const table = tables.find(
                        (table) => table.id === order.tableId
                      )
                      if (!table) return null
                      return (
                        <OrderCard key={order.id} order={order} table={table} />
                      )
                    })}
                </Accordion>
                <Stat textAlign='right' pr={4}>
                  <StatLabel>Total Due</StatLabel>
                  <StatNumber>
                    {toDollar(
                      sum(
                        orders
                          .filter(
                            (order) =>
                              order.tableId === selectedTable.id &&
                              order.status !== 'Paid'
                          )
                          .map((order) => order.subtotal + order.tax)
                      )
                    )}
                  </StatNumber>
                </Stat>
              </Stack>
              <Divider />
              <Stack px={4} spacing={4} flex={0}>
                <Heading size='md'>Paid Orders</Heading>
                <Accordion allowMultiple>
                  {orders
                    .filter(
                      (order) =>
                        order.tableId === selectedTable.id &&
                        order.status === 'Paid'
                    )
                    .map((order: Order) => {
                      const table = tables.find(
                        (table) => table.id === order.tableId
                      )
                      if (!table) return null
                      return (
                        <OrderCard key={order.id} order={order} table={table} />
                      )
                    })}
                </Accordion>
              </Stack>
            </Stack>
          ) : (
            <Stack>
              <Stack p={4} spacing={4}>
                <Heading size='md'>All Orders</Heading>
                <Accordion allowToggle>
                  {orders.map((order: Order) => {
                    const table = tables.find(
                      (table) => table.id === order.tableId
                    )
                    if (!table) return null
                    return (
                      <OrderCard key={order.id} order={order} table={table} />
                    )
                  })}
                </Accordion>
              </Stack>
            </Stack>
          )}
        </Card>
      </Flex>
    </Flex>
  )
}
