import { CloseIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { isEmpty, toNumber, uniqueId } from 'lodash'
import { useContext, useState } from 'react'
import { formatFileSize, useCSVDownloader, useCSVReader } from 'react-papaparse'
import { RestaurantContext } from '../../constants/contexts'
import { addMenuItem } from '../../database/writes/menu'
import { toDollar } from '../../utils/money'

export default function MenuCSVModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const toast = useToast()
  const { CSVReader } = useCSVReader()
  const { CSVDownloader, Type } = useCSVDownloader()
  const [isDragOver, setIsDragOver] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [data, setData] = useState<
    [string, string, string, number, File | undefined][]
  >([])
  const restaurant = useContext(RestaurantContext)

  const convertData = (data: string[][]) => {
    data.shift()
    const newData: [string, string, string, number, File | undefined][] = []
    data.forEach((fields, index) => {
      const price = toNumber(data[index][3].replace(/[^0-9.]/g, '') || 0)
      newData.push([
        fields[0],
        fields[1],
        fields[2],
        price > 0 ? price * 100 : 0,
        undefined,
      ])
    })
    return newData
  }

  const onSubmit = async () => {
    if (!restaurant) return
    setIsSubmitting(true)
    await Promise.all(
      data.map((fields) => {
        return addMenuItem({
          restaurantId: restaurant.id,
          name: fields[0],
          description: fields[1],
          category: fields[2],
          basePrice: fields[3],
          picture: fields[4],
          modifications: [],
        })
      })
    )
    setIsSubmitting(false)
    setData([])
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setData([])
      }}
    >
      <ModalOverlay />
      <ModalContent maxW='80%' minW='30%'>
        <ModalHeader>Upload CSV</ModalHeader>
        <ModalBody>
          <CSVReader
            onUploadAccepted={(result: {
              data: string[][]
              errors: any
              meta: any
            }) => {
              setIsDragOver(false)
              if (isEmpty(result.data)) return
              if (result.data[result.data.length - 1].length !== 4)
                result.data.pop()
              const isValid = result.data.every((fields, index) => {
                if (fields.length !== 4) return false
                if (index === 0)
                  return (
                    fields[0].toLowerCase() === 'name' &&
                    fields[1].toLowerCase() === 'description' &&
                    fields[2].toLowerCase() === 'category' &&
                    fields[3].toLowerCase() === 'price'
                  )
                return true
              })
              if (!isValid)
                return toast({
                  title: 'Invalid CSV Data.',
                  description: 'Please follow the template format',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                  position: 'top',
                })
              setData(convertData(result.data))
            }}
            onDragOver={(e: DragEvent) => {
              e.preventDefault()
              setIsDragOver(true)
            }}
            onDragLeave={(e: DragEvent) => {
              e.preventDefault()
              setIsDragOver(false)
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }: any) => (
              <>
                <Flex
                  {...getRootProps()}
                  align='center'
                  justify='center'
                  border='2px dashed #CCC'
                  rounded={20}
                  flexDir='column'
                  p={8}
                  borderColor={isDragOver ? '#686868' : '#CCC'}
                  position='relative'
                >
                  {acceptedFile ? (
                    <>
                      <Stack align='center' justify='center'>
                        <Text>{acceptedFile.name}</Text>
                        <Text>{formatFileSize(acceptedFile.size)}</Text>
                        <ProgressBar />
                      </Stack>
                      <IconButton
                        icon={<CloseIcon />}
                        position='absolute'
                        colorScheme='red'
                        variant='ghost'
                        right={2}
                        top={2}
                        size='sm'
                        isRound
                        {...getRemoveFileProps()}
                        onClickCapture={() => {
                          setData([])
                        }}
                      />
                    </>
                  ) : (
                    'Drop CSV file here or click to upload'
                  )}
                </Flex>
              </>
            )}
          </CSVReader>
          <CSVDownloader
            type={Type.Link}
            filename='menu-csv-template'
            bom={true}
            data={[
              {
                Name: 'Example Name',
                Description: 'This is an example description',
                Category: 'Example Category',
                Price: '5 (please format as number)',
              },
            ]}
          >
            <Text textDecor='underline' display='inline'>
              Download template
            </Text>
          </CSVDownloader>
          {!isEmpty(data) && (
            <TableContainer pt={4}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th>Category</Th>
                    <Th>Image</Th>
                    <Th isNumeric>Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((fields, index) => (
                    <Tr key={uniqueId()}>
                      <Td>{fields[0]}</Td>
                      <Td>{fields[1]}</Td>
                      <Td>{fields[2]}</Td>
                      <Td>
                        <input
                          type='file'
                          multiple={false}
                          onChange={(e) => {
                            setData((data) => {
                              data[index][4] = e.target.files
                                ? e.target.files[0]
                                : undefined
                              return data
                            })
                          }}
                          accept='.jpg,.png,.jpeg'
                        />
                      </Td>
                      <Td isNumeric>{toDollar(fields[3])}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            bg='brand.400'
            color='shade.white'
            _hover={{
              bg: 'brand.600',
            }}
            mr={3}
            onClick={onSubmit}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
          <Button
            variant='ghost'
            onClick={() => {
              onClose()
              setData([])
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
