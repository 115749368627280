import {
  AddIcon,
  ChevronDownIcon,
  DeleteIcon,
  DownloadIcon,
} from '@chakra-ui/icons'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react'
import { Document, Image, Page, pdf, Text, View } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { max } from 'lodash'
import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useState } from 'react'
import { listenToTablesByRestaurantId } from '../../database/reads/tables'
import { addTable, deleteTable } from '../../database/writes/tables'
import { Restaurant, Table } from '../../types'

export default function QRCodesCard({
  restaurant,
}: {
  restaurant: Restaurant
}) {
  const [tables, setTables] = useState<Table[]>([])

  useEffect(
    () =>
      listenToTablesByRestaurantId({
        restaurantId: restaurant.id,
        cb: setTables,
      }),
    [restaurant]
  )

  const downloadQRCodesPDF = async () => {
    const qrCodes = Array.from(
      document.getElementsByClassName('qrCode')
    ) as HTMLCanvasElement[]

    // Generate PDF
    const pdfDoc = await pdf(
      <Document>
        <Page
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: 30,
          }}
        >
          {qrCodes.map((canvas, index) => (
            <View
              key={index}
              style={{
                width: '33%',
                padding: 20,
                marginBottom: 30,
                border: '1px dashed black',
              }}
            >
              <Image src={canvas.toDataURL()} />
              <Text
                style={{ width: '100%', textAlign: 'center', marginTop: 10 }}
              >
                Table {canvas.id}
              </Text>
            </View>
          ))}
        </Page>
      </Document>
    ).toBlob()
    saveAs(pdfDoc, `${restaurant.name} QR Codes.pdf`)
  }

  const downloadQRCodes = async () => {
    const qrCodes = Array.from(
      document.getElementsByClassName('qrCode')
    ) as HTMLCanvasElement[]
    const zip = new JSZip()
    await Promise.all(
      qrCodes.map(
        (canvas) =>
          new Promise((resolve) =>
            canvas.toBlob((blob) => {
              if (blob) {
                zip.file(`Table${canvas.id}.png`, blob)
              }
              return resolve(null)
            })
          )
      )
    )
    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, `${restaurant.name} QR Codes.zip`)
  }

  return (
    <Card variant='ghost' w='100%'>
      <CardHeader pt='0'>
        <Flex justify='space-between' align='center'>
          <Heading as='h2' size='lg' my={2}>
            QR Codes
          </Heading>
          <Menu closeOnSelect={false}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<DownloadIcon />}
                aria-label='Download QR Codes'
                onClick={downloadQRCodesPDF}
              >
                Download as PDF
              </MenuItem>
              <MenuItem
                icon={<DownloadIcon />}
                aria-label='Download QR Codes'
                onClick={downloadQRCodes}
              >
                Download as Pictures
              </MenuItem>
              <MenuItem
                icon={<AddIcon />}
                onClick={() =>
                  addTable({
                    restaurantId: restaurant.id,
                    tableNumber:
                      1 +
                      (max(tables.map(({ tableNumber }) => tableNumber)) ?? 0),
                  })
                }
              >
                Add New Table
              </MenuItem>
              <MenuItem
                icon={<DeleteIcon />}
                color='red.500'
                onClick={() =>
                  deleteTable({
                    restaurantId: restaurant.id,
                    id: tables[tables.length - 1].id,
                  })
                }
              >
                Delete Last Table
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody>
        <Flex flexWrap='wrap' gap={4}>
          {tables.map(({ id, tableNumber }) => (
            <Card key={id} width={168} variant='outline'>
              <CardBody>
                <Stack alignItems='center'>
                  <Heading size='sm'>Table {tableNumber}</Heading>
                  <QRCodeCanvas
                    id={tableNumber.toString()}
                    className='qrCode'
                    value={`https://www.danqr.com/${restaurant?.id}/${id}`}
                    size={128}
                    bgColor='#ffffff'
                    fgColor='#000000'
                    level='L'
                    includeMargin={false}
                  />
                </Stack>
              </CardBody>
            </Card>
          ))}
        </Flex>
      </CardBody>
    </Card>
  )
}
