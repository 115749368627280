import { CalendarIcon } from '@chakra-ui/icons'
import {
  Card,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import CategoryPie from '../components/analytics/CategoryPie'
import ItemPie from '../components/analytics/ItemPie'
import Revenue from '../components/analytics/Revenue'
import Statistic from '../components/analytics/Statistic'
import TermComparison from '../components/analytics/TermComparison'
import TermSelectModal from '../components/analytics/TermSelectModal'
import NavBar from '../components/NavBar'
import { RestaurantContext } from '../constants/contexts'
import { listenToDishesByOrderId } from '../database/reads/dishes'
import { getMenuItemById } from '../database/reads/menu'
import { getOrdersByTerm } from '../database/reads/orders'
import { Dish, DishOrder, NameDish, Order } from '../types'

export default function Analytics() {
  const {
    isOpen: isTerm1Open,
    onOpen: onTerm1Open,
    onClose: onTerm1Close,
  } = useDisclosure()
  const {
    isOpen: isTerm2Open,
    onOpen: onTerm2Open,
    onClose: onTerm2Close,
  } = useDisclosure()

  const [start1, setStart1] = useState<Date | null>(null)
  const [end1, setEnd1] = useState<Date | null>(null)
  const [start2, setStart2] = useState<Date | null>(null)
  const [end2, setEnd2] = useState<Date | null>(null)
  const [orders1, setOrders1] = useState<DishOrder[]>([])
  const [orders2, setOrders2] = useState<DishOrder[]>([])

  const restaurant = useContext(RestaurantContext)

  const handleTerm1Change = (startDate: Date, endDate: Date) => {
    setStart1(startDate)
    setEnd1(endDate)

    if (!restaurant) {
      return
    }

    getOrdersByTerm({
      restaurantId: restaurant.id,
      startDate,
      endDate,
      cb: (result: Order[]) => {
        const ordersWithDishes = result.map((order) => {
          return {
            ...order,
            dishes: [] as NameDish[],
          }
        })

        const dishPromises = ordersWithDishes.map((order) => {
          return new Promise<void>((resolve) => {
            listenToDishesByOrderId({
              restaurantId: restaurant.id,
              tableId: order.tableId,
              orderId: order.id,
              cb: async (dishes: Dish[]) => {
                const dishesWithNames = await Promise.all(
                  dishes.map(async (dish) => {
                    const menuItem = await getMenuItemById({
                      restaurantId: restaurant.id,
                      itemId: dish.itemId,
                    })
                    return {
                      ...dish,
                      name: menuItem?.name || '',
                      category: menuItem?.category || '',
                    }
                  })
                )
                order.dishes = dishesWithNames
                resolve()
              },
            })
          })
        })

        Promise.all(dishPromises).then(() => {
          setOrders1(ordersWithDishes)
        })
      },
    })
  }

  const handleTerm2Change = (startDate: Date, endDate: Date) => {
    setStart2(startDate)
    setEnd2(endDate)

    if (!restaurant) {
      return
    }

    getOrdersByTerm({
      restaurantId: restaurant.id,
      startDate,
      endDate,
      cb: (result: Order[]) => {
        const ordersWithDishes = result.map((order) => {
          return {
            ...order,
            dishes: [] as NameDish[],
          }
        })

        const dishPromises = ordersWithDishes.map((order) => {
          return new Promise<void>((resolve) => {
            listenToDishesByOrderId({
              restaurantId: restaurant.id,
              tableId: order.tableId,
              orderId: order.id,
              cb: async (dishes: Dish[]) => {
                const dishesWithNames = await Promise.all(
                  dishes.map(async (dish) => {
                    const menuItem = await getMenuItemById({
                      restaurantId: restaurant.id,
                      itemId: dish.itemId,
                    })
                    return {
                      ...dish,
                      name: menuItem?.name || '',
                      category: menuItem?.category || '',
                    }
                  })
                )
                order.dishes = dishesWithNames
                resolve()
              },
            })
          })
        })

        Promise.all(dishPromises).then(() => {
          setOrders2(ordersWithDishes)
        })
      },
    })
  }

  return (
    <>
      <Flex flexDir='row' h='100vh' bg='background.50'>
        <NavBar />
        <Flex flex={1} flexDir='column' overflow='auto'>
          <Stack p={10} bgColor='#f7f8fa'>
            <Heading size='lg'>Analytics</Heading>
            <HStack>
              <Flex
                align='center'
                gap={2}
                onClick={onTerm1Open}
                cursor='pointer'
              >
                <CalendarIcon color='#e74536' />
                {start1 && end1 ? (
                  <Text>
                    {'Showing T1: '}
                    {start1.toDateString()} to {end1.toDateString()}
                  </Text>
                ) : (
                  <Text>Select a primary term to view</Text>
                )}
              </Flex>
              {start1 && end1 && (
                <>
                  <Text> | </Text>
                  <Flex
                    align='center'
                    gap={2}
                    onClick={onTerm2Open}
                    cursor='pointer'
                  >
                    <CalendarIcon color='#6bbaec' />
                    {start2 && end2 ? (
                      <Text>
                        {'Comparing T2: '}
                        {start2.toDateString()} to {end2.toDateString()}
                      </Text>
                    ) : (
                      <Text>Select a secondary term to compare</Text>
                    )}
                  </Flex>
                </>
              )}
            </HStack>
          </Stack>
          <Grid
            p='40px'
            pt='0'
            flex={1}
            bgColor='#f7f8fa'
            templateRows='repeat(10, 1fr)'
            templateColumns='repeat(20, 1fr)'
            gap={4}
          >
            {orders1.length === 0 ? (
              <GridItem
                as={Card}
                rounded='xl'
                rowSpan={10}
                colSpan={20}
                bg='shade.white'
                p='20px'
              >
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  h='100%'
                >
                  <img src='analyze.jpg' alt='Empty State' width='50%' />
                  <Heading size='md'>
                    Pick a time period to view your data
                  </Heading>
                </Flex>
              </GridItem>
            ) : (
              <>
                <GridItem
                  as={Card}
                  rounded='xl'
                  rowSpan={4}
                  colSpan={7}
                  bg='shade.white'
                  p='20px'
                >
                  <ItemPie orders1={orders1} orders2={orders2} />
                </GridItem>
                <GridItem
                  as={Card}
                  rounded='xl'
                  rowSpan={4}
                  colSpan={7}
                  bg='shade.white'
                  p='20px'
                >
                  <CategoryPie orders1={orders1} orders2={orders2} />
                </GridItem>
                <GridItem
                  as={Card}
                  rounded='xl'
                  rowSpan={2}
                  colSpan={6}
                  bg='shade.white'
                  p='20px'
                >
                  <Statistic orders1={orders1} orders2={orders2} term={'T1'}/>
                </GridItem>
                <GridItem
                  as={Card}
                  rounded='xl'
                  rowSpan={2}
                  colSpan={6}
                  bg='shade.white'
                  p='20px'
                >
                  <Statistic orders1={orders2} orders2={orders1} term={'T2'}/>
                </GridItem>
                <GridItem
                  as={Card}
                  rounded='xl'
                  rowSpan={5}
                  colSpan={11}
                  bg='shade.white'
                  p='20px'
                >
                  <Revenue orders1={orders1} orders2={orders2} />
                </GridItem>
                <GridItem
                  as={Card}
                  rounded='xl'
                  rowSpan={5}
                  colSpan={9}
                  bg='shade.white'
                  p='20px'
                >
                  <TermComparison orders1={orders1} orders2={orders2} />
                </GridItem>
              </>
            )}
          </Grid>
        </Flex>
      </Flex>
      <TermSelectModal
        isOpen={isTerm1Open}
        onClose={onTerm1Close}
        setTerm={handleTerm1Change}
      />
      <TermSelectModal
        isOpen={isTerm2Open}
        onClose={onTerm2Close}
        setTerm={handleTerm2Change}
      />
    </>
  )
}
