import {
  Card,
  CardFooter,
  CardHeader,
  Circle,
  Flex,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react'
import { Order, Table } from '../types'

export default function TableCard({
  table,
  orders,
  handleClick,
  isSelected,
}: {
  table: Table
  orders: Order[]
  handleClick: any
  isSelected: boolean
}) {
  return (
    <Card
      variant='filled'
      translateY={isSelected ? -2 : 0}
      h='auto'
      onClick={handleClick}
      _hover={{
        bg: 'messenger.300',
      }}
      cursor='pointer'
      whiteSpace='nowrap'
      backgroundColor='white'
      borderRadius='0'
      // bg={isSelected ? 'messenger.300' : undefined}
    >
      <CardHeader>
        <Heading size='md'>
          {table.tableNumber > 9 ? table.tableNumber : '0' + table.tableNumber}
        </Heading>
      </CardHeader>
      <CardFooter>
        <Flex flexDir='column'>
          <Text size='s' mb='5px'>
            Status
          </Text>
          <HStack spacing={1} justifyContent='space-between'>
            <Circle size='30px' bg='red.100'>
              <Text fontSize='l' fontWeight='bold' color='red.400'>
                {orders.filter((order) => order.status === 'New').length}
              </Text>
            </Circle>
            <Circle size='30px' bg='yellow.100'>
              <Text fontSize='l' fontWeight='bold' color='yellow.400'>
                {
                  orders.filter((order) => order.status === 'In Progress')
                    .length
                }
              </Text>
            </Circle>
            <Circle size='30px' bg='green.100'>
              <Text fontSize='l' fontWeight='bold' color='green.400'>
                {orders.filter((order) => order.status === 'Served').length}
              </Text>
            </Circle>
          </HStack>
        </Flex>
      </CardFooter>
    </Card>
  )
}
