import { useEffect, useState } from 'react'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { DateRevenue, DishOrder } from '../../types'
import { revenueOnDate } from '../../utils/analytics'

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload

    return (
      <div
        style={{
          background: 'white',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          {data.date}
        </div>
        <div style={{ marginBottom: '5px' }}>T1 Revenue: ${data.revenue}.00</div>
      </div>
    )
  }

  return null
}

export default function Revenue({
  orders1,
  orders2,
}: {
  orders1: DishOrder[]
  orders2: DishOrder[]
}) {
  const [data, setData] = useState<DateRevenue[]>([])

  useEffect(() => {
    const processedData: DateRevenue[] = revenueOnDate(orders1)
    setData(processedData)
  }, [orders1])

  return (
    <ResponsiveContainer width='100%' height='100%'>
      {data.length > 0 ? (
        <LineChart
          width={500}
          height={300}
          data={data}
        >
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type='monotone'
            dataKey='revenue'
            stroke='#e74536'
            activeDot={{ r: 8 }}
          />
          <Line type='monotone' dataKey='revenue2' stroke='#6bbaec' />
        </LineChart>
      ) : (
        <>Please select a term with data!</>
      )}
    </ResponsiveContainer>
  )
}
