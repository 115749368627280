import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Address, AdminStatus, Restaurant } from '../../types'
import { genericConverter } from '../../utils/firestore'
import { getImageURL, uploadImage } from '../storage/images'
import { addEmployee } from './employees'

const restaurantsRef = collection(db, 'Restaurants')

export async function createRestaurant({
  name,
  address,
  picture,
  tax,
  userId,
  email,
}: {
  name: string
  address: Address
  picture: File
  tax: number
  userId: string
  email: string
}) {
  try {
    const restaurantRef = await addDoc(restaurantsRef, {
      name,
      address,
      logo: '',
      isPlainMenu: false,
      hasPaid: false,
      tax,
      subscriptions: [],
    })
    await addEmployee({
      restaurantId: restaurantRef.id,
      userId,
      status: AdminStatus.admin,
      email,
    })
    const imageId = await uploadImage({
      restaurantId: restaurantRef.id,
      image: picture,
      id: 'logo',
    })
    const imageURL = await getImageURL({
      restaurantId: restaurantRef.id,
      imageId,
    })
    await updateDoc(restaurantRef, { logo: imageURL })
    return restaurantRef
  } catch (err) {
    console.error(err)
  }
}

interface UpdateRestaurant extends Partial<Restaurant> {
  restaurantId: string
}
export async function updateRestaurant({
  restaurantId,
  ...updateFields
}: UpdateRestaurant) {
  const restaurantRef = doc(db, 'Restaurants', restaurantId).withConverter(
    genericConverter<Partial<Restaurant>>()
  )
  try {
    return updateDoc(restaurantRef, updateFields)
  } catch (err) {
    console.error(err)
  }
}
