import { useEffect, useState } from 'react'
import { Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts'
import { DishOrder, RankedCategory } from '../../types'
import { rankCategories } from '../../utils/analytics'

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload

    return (
      <div
        style={{
          background: 'white',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          {data.name}
        </div>
        <div style={{ marginBottom: '5px' }}>Total Sold in T1: {data.quantity}</div>
        <div>Makes up {(data.percentage * 100).toFixed(2)}% of T1 sales</div>
      </div>
    )
  }

  return null
}

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

  return (
    <g style={{ outline: 'none' }}>
      <text x={cx} y={cy} dy={8} textAnchor='middle'>
        Top Categories
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  )
}

export default function CategoryPie({
  orders1,
  orders2,
}: {
  orders1: DishOrder[]
  orders2: DishOrder[]
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [data, setData] = useState<RankedCategory[]>([])

  useEffect(() => {
    const processedData = rankCategories(orders1)
    setData(processedData)
  }, [orders1])

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index)
  }

  const COLORS = ['#e74536', '#6bbaec', '#6987c9', '#7880b5']

  return (
    <ResponsiveContainer width='100%' height='100%'>
      {data.length > 0 ? (
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data.map((entry, index) => ({
              ...entry,
              fill: index < 4 ? COLORS[index] : '#bcc4db',
            }))}
            cx='50%'
            cy='50%'
            innerRadius={80}
            outerRadius={100}
            dataKey='quantity'
            onMouseEnter={onPieEnter}
          />
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      ) : (
        <>Please select a term with data!</>
      )}
    </ResponsiveContainer>
  )
}
