import {
  Badge,
  Button,
  Card,
  CardBody,
  Circle,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineFileSearch,
  AiOutlinePicture,
} from 'react-icons/ai'
import { useNavigate } from 'react-router'
import NavBar from '../components/NavBar'
import { UserContext } from '../constants/contexts'
import { getEmploymentsByUserId } from '../database/reads/employees'
import { deleteEmployee, updateEmployee } from '../database/writes/employees'
import { createRestaurant } from '../database/writes/restaurants'
import { AdminUser, Employments } from '../types'

function CreateRestaurantForm({ user }: { user: AdminUser }) {
  const navigate = useNavigate()
  const toast = useToast()

  const [name, setName] = useState('')
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [country, setCountry] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [picture, setPicture] = useState<File>()
  const [tax, setTax] = useState(NaN)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const isNameInvalid = !name
  const isAddressInvalid =
    !line1 || !city || !province || !country || !postalCode || isNaN(tax)
  const isPictureInvalid = !picture

  const onSubmit = async () => {
    if (isNameInvalid || isAddressInvalid || isPictureInvalid) {
      toast({
        title: 'Please fill in all required fields',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
      return
    }
    setIsSubmitting(true)
    const address = {
      line1,
      line2,
      city,
      province,
      country,
      postalCode,
    }
    const restaurantRef = await createRestaurant({
      name,
      address,
      picture,
      tax,
      userId: user.id,
      email: user.email,
    })
    setIsSubmitting(false)
    if (!restaurantRef) {
      console.error('Create restaurant failed')
      return
    }
    sessionStorage.setItem('restaurantId', restaurantRef.id)
    navigate('/edit')
    navigate(0)
  }

  const handleBoxClick = () => {
    const fileInput = document.getElementById('file-input') as HTMLInputElement
    fileInput.click()
  }

  return (
    <Flex flexDir='column' minW={512} align='center' p={10} gap={4}>
      <Circle
        size={32}
        borderWidth='1px'
        borderColor='gray.200'
        overflow='hidden'
        onClick={handleBoxClick}
        cursor='pointer'
      >
        {picture ? (
          <Image
            src={URL.createObjectURL(picture)}
            objectFit='contain'
            p={2}
            w={32}
            h={32}
          />
        ) : (
          <Icon as={AiOutlinePicture} boxSize={12} />
        )}
      </Circle>
      <input
        id='file-input'
        type='file'
        multiple={false}
        onChange={(e) => {
          setPicture(e.target.files ? e.target.files[0] : undefined)
        }}
        accept='.jpg,.png,.jpeg'
        style={{ display: 'none' }}
      />
      <Input
        placeholder='Restaurant Name'
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />
      <InputGroup>
        <Input
          placeholder='Tax'
          value={isNaN(tax) ? '' : tax}
          type='number'
          onChange={(e) => setTax(e.currentTarget.valueAsNumber)}
        />
        <InputRightAddon children='%' />
      </InputGroup>
      <HStack w='100%'>
        <Divider />
        <Text color='gray.500'>Address</Text>
        <Divider />
      </HStack>
      <Input
        placeholder='Line1'
        value={line1}
        onChange={(e) => setLine1(e.currentTarget.value)}
      />
      <Input
        placeholder='Line2'
        value={line2}
        onChange={(e) => setLine2(e.currentTarget.value)}
      />
      <Input
        placeholder='City'
        value={city}
        onChange={(e) => setCity(e.currentTarget.value)}
      />
      <Input
        placeholder='Province'
        value={province}
        onChange={(e) => setProvince(e.currentTarget.value)}
      />
      <Input
        placeholder='Country'
        value={country}
        onChange={(e) => setCountry(e.currentTarget.value)}
      />
      <Input
        placeholder='Postal Code'
        value={postalCode}
        onChange={(e) => setPostalCode(e.currentTarget.value)}
      />
      <Button
        bg='brand.400'
        color='shade.white'
        _hover={{
          bg: 'brand.600',
        }}
        onClick={onSubmit}
        isLoading={isSubmitting}
      >
        Create Restaurant
      </Button>
    </Flex>
  )
}

export function EnterRestaurant({
  user,
  setShowCreate,
}: {
  user: AdminUser
  setShowCreate: (val: boolean) => void
}) {
  const navigate = useNavigate()

  const [employments, setEmployments] = useState<Employments[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem('restaurantId')) navigate('/')
  }, [navigate])

  useEffect(() => {
    setIsLoading(true)
    getEmploymentsByUserId({
      restaurantIds: user.restaurantIds,
      userId: user.id,
    }).then((res) => {
      setEmployments(res)
      setIsLoading(false)
    })
  }, [user])

  const reload = () => {
    getEmploymentsByUserId({
      restaurantIds: user.restaurantIds,
      userId: user.id,
    }).then((res) => {
      setEmployments(res)
    })
  }

  const current = employments.filter((emp) => !emp.employee.isInvited)
  const invites = employments.filter((emp) => emp.employee.isInvited)

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }
  
  if (isEmpty(employments))
    return (
      <Stack align='center'>
        <Icon as={AiOutlineFileSearch} boxSize={24} />
        <Heading py={8}>No Restaurants On This Account</Heading>
        <Text>
          Are you a restaurant owner? If so, register your restaurant in our app{' '}
          <Link textDecor='underline' onClick={() => setShowCreate(true)}>
            here
          </Link>
          .
        </Text>
        <Text>
          Are you an employee? If so, please ask your supervisor for an invite
          to join the restaurant.
        </Text>
      </Stack>
    )

  return (
    <HStack spacing={8}>
      <Flex flexWrap='wrap' gap={4} justify='stretch' align='stretch'>
        {current.map(({ restaurant, employee }) => (
          <Card
            key={restaurant.id}
            variant='outline'
            cursor='pointer'
            _hover={{ background: '#F7FAFC' }}
            px={8}
            justify='center'
            align='center'
            onClick={() => {
              sessionStorage.setItem('restaurantId', restaurant.id)
              navigate('/')
              navigate(0)
            }}
          >
            <CardBody>
              <Stack alignItems='center'>
                <Circle
                  size={24}
                  borderWidth='1px'
                  borderColor='gray.200'
                  overflow='hidden'
                  cursor='pointer'
                >
                  <Image
                    src={restaurant.logo}
                    objectFit='cover'
                    w={24}
                    h={24}
                  />
                </Circle>
                <Heading size='sm'>{restaurant.name}</Heading>
                <Badge>{employee.status}</Badge>
              </Stack>
            </CardBody>
          </Card>
        ))}
      </Flex>
      {!isEmpty(current) && !isEmpty(invites) && (
        <Divider orientation='vertical' h={40} />
      )}
      {!isEmpty(invites) && (
        <Stack align='center' alignSelf='start'>
          <Heading size='md' pb={2}>
            Invites
          </Heading>
          {invites.map(({ restaurant, employee }) => (
            <HStack
              key={restaurant.id}
              borderBottom='1px'
              borderBottomColor='gray.200'
            >
              <Text>{restaurant.name}</Text>
              <Badge>{employee.status}</Badge>
              <Spacer pl={8} />
              <IconButton
                aria-label='accept'
                icon={<Icon as={AiOutlineCheck} />}
                variant='ghost'
                colorScheme='green'
                onClick={() => {
                  updateEmployee({
                    restaurantId: restaurant.id,
                    userId: user.id,
                    isInvited: false,
                  }).then(reload)
                }}
              />
              <IconButton
                aria-label='decline'
                icon={<Icon as={AiOutlineClose} />}
                variant='ghost'
                colorScheme='red'
                onClick={() => {
                  deleteEmployee({
                    restaurantId: restaurant.id,
                    userId: user.id,
                  })
                }}
              />
            </HStack>
          ))}
        </Stack>
      )}
    </HStack>
  )
}

export default function Enter() {
  const user = useContext(UserContext)
  const [showCreate, setShowCreate] = useState(false)

  if (!user) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex h='100vh' flexDir='row'>
      <NavBar onlyLogOut />
      <Flex
        flex={1}
        justifyContent='center'
        alignItems='center'
        flexDir='column'
      >
        <Flex flexDir='column' overflow='auto' flex={1} align='center'>
          <Flex flex={1} align='center' justify='center'>
            {showCreate ? (
              <CreateRestaurantForm user={user} />
            ) : (
              <EnterRestaurant user={user} setShowCreate={setShowCreate} />
            )}
          </Flex>
          <Text textAlign='center' mb={10}>
            {showCreate
              ? 'To enter an existing restaurant, '
              : 'To create a new restaurant, '}
            <Link
              onClick={() => setShowCreate((cur) => !cur)}
              textDecor='underline'
            >
              click here
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
