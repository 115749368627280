import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore'
import { compact } from 'lodash'
import { db } from '../../constants/firebase'
import { Employee, Employments, Restaurant } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToEmployeesByRestaurantId({
  restaurantId,
  cb,
}: {
  restaurantId: string
  cb: (employees: Employee[]) => void
}) {
  const employeesRef = collection(db, 'Restaurants', restaurantId, 'Employees')
  return onSnapshot(
    query(employeesRef).withConverter(genericConverter<Employee>()),
    (employeeSnapshot) => {
      cb(employeeSnapshot.docs.map((doc) => doc.data()))
    },
    (err) => console.error(err)
  )
}

export async function getEmploymentsByUserId({
  restaurantIds,
  userId,
}: {
  restaurantIds: string[]
  userId: string
}) {
  return compact(
    await Promise.all(
      restaurantIds.map(async (restaurantId) => {
        const restaurantRef = doc(db, 'Restaurants', restaurantId)
        const employeeRef = doc(
          db,
          'Restaurants',
          restaurantId,
          'Employees',
          userId
        )
        try {
          const [restaurantDoc, employeeDoc] = await Promise.all([
            getDoc(restaurantRef.withConverter(genericConverter<Restaurant>())),
            getDoc(employeeRef.withConverter(genericConverter<Employee>())),
          ])
          if (!restaurantDoc.exists() || !employeeDoc.exists()) return undefined
          return {
            restaurant: restaurantDoc.data(),
            employee: employeeDoc.data(),
          } as Employments
        } catch (err) {
          console.error(err)
        }
      })
    )
  )
}
