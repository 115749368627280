import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HeadingProps,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import {
  deleteImage,
  getImageURL,
  uploadImage,
} from '../../database/storage/images'
import { updateRestaurant } from '../../database/writes/restaurants'
import { Restaurant } from '../../types'

const headingProps: HeadingProps = { size: 'sm', mb: 2 }

function InformationCardBody({ restaurant }: { restaurant: Restaurant }) {
  const toast = useToast()
  const [isBtnLoading, setIsBtnLoading] = useState(false)

  const [name, setName] = useState(restaurant.name)
  const [line1, setLine1] = useState(restaurant.address.line1)
  const [line2, setLine2] = useState(restaurant.address.line2 ?? '')
  const [city, setCity] = useState(restaurant.address.city)
  const [province, setProvince] = useState(restaurant.address.province)
  const [country, setCountry] = useState(restaurant.address.country)
  const [postalCode, setPostalCode] = useState(restaurant.address.postalCode)
  const [tax, setTax] = useState(restaurant.tax)

  const [picture, setPicture] = useState<File>()

  const onSubmit = async () => {
    setIsBtnLoading(true)
    if (
      !name ||
      !line1 ||
      !city ||
      !province ||
      !country ||
      !postalCode ||
      isNaN(tax)
    ) {
      setIsBtnLoading(false)
      toast({
        title: 'Please fill in all required fields',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
      return
    }
    const updateFields: Partial<Restaurant> = {
      name,
      address: {
        line1,
        line2,
        city,
        province,
        country,
        postalCode,
      },
      tax,
    }
    if (picture) {
      await deleteImage({ restaurantId: restaurant.id, imageId: 'logo' })
      const imageId = await uploadImage({
        restaurantId: restaurant.id,
        image: picture,
        id: 'logo',
      })
      const imageURL = await getImageURL({
        restaurantId: restaurant.id,
        imageId,
      })
      updateFields.logo = imageURL
    }
    await updateRestaurant({ restaurantId: restaurant.id, ...updateFields })
    setIsBtnLoading(false)
    toast({
      title: 'Update success',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    })
    setPicture(undefined)
  }

  const handleBoxClick = () => {
    const fileInput = document.getElementById('file-input') as HTMLInputElement
    fileInput.click()
  }

  return (
    <Flex flexDirection='column' gap={4}>
      <Flex flexDirection='row' gap={4}>
        <Flex flexDirection='column' flex={5}>
          <Heading {...headingProps}>Restaurant Name</Heading>
          <Input
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            mb={4}
          />
          <Heading {...headingProps}>Tax</Heading>
          <InputGroup>
            <Input
              value={isNaN(tax) ? '' : tax}
              type='number'
              onChange={(e) => setTax(e.currentTarget.valueAsNumber)}
              mb={4}
            />
            <InputRightAddon children='%' />
          </InputGroup>
          <Flex flexDirection='column' gap={4}>
            <Heading {...headingProps} mb={0}>
              Address
            </Heading>
            <Box ml={4}>
              <Heading {...headingProps}>Line1</Heading>
              <Input
                value={line1}
                onChange={(e) => setLine1(e.currentTarget.value)}
                mb={2}
              />
              <Heading {...headingProps}>Line2</Heading>
              <Input
                value={line2}
                onChange={(e) => setLine2(e.currentTarget.value)}
                mb={2}
              />
              <Heading {...headingProps}>City</Heading>
              <Input
                value={city}
                onChange={(e) => setCity(e.currentTarget.value)}
                mb={2}
              />
              <Heading {...headingProps}>Province</Heading>
              <Input
                value={province}
                onChange={(e) => setProvince(e.currentTarget.value)}
                mb={2}
              />
              <Heading {...headingProps}>Country</Heading>
              <Input
                value={country}
                onChange={(e) => setCountry(e.currentTarget.value)}
                mb={2}
              />
              <Heading {...headingProps}>Postal Code</Heading>
              <Input
                value={postalCode}
                onChange={(e) => setPostalCode(e.currentTarget.value)}
                mb={2}
              />
            </Box>
          </Flex>
          <Button
            bg='brand.400'
            color='shade.white'
            _hover={{
              bg: 'brand.600',
            }}
            onClick={onSubmit}
            mt={4}
            isLoading={isBtnLoading}
          >
            Save
          </Button>
        </Flex>
        <Flex flexDirection='column' gap={2} flex={3}>
          <Heading {...headingProps}>Restaurant Logo</Heading>
          <Image
            alt={restaurant.name}
            src={picture ? URL.createObjectURL(picture) : restaurant.logo}
            boxSize={48}
            borderRadius='full'
            objectFit='contain'
            p='2px'
            shadow='base'
            bg='shade.white'
            cursor='pointer'
            onClick={handleBoxClick}
          />
          <input
            id='file-input'
            type='file'
            multiple={false}
            onChange={(e) => {
              setPicture(e.target.files ? e.target.files[0] : undefined)
            }}
            accept='.jpg,.png,.jpeg'
            style={{ display: 'none' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default function InformationCard({
  restaurant,
}: {
  restaurant: Restaurant
}) {
  return (
    <Card variant='ghost' w='100%'>
      <CardHeader pt='0'>
        <Flex justify='space-between' align='center'>
          <Heading as='h2' size='lg' my={2}>
            Information
          </Heading>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody>
        <InformationCardBody restaurant={restaurant} />
      </CardBody>
    </Card>
  )
}
