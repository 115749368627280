import { Accordion, Card, Flex, Heading, HStack, Stack } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import OrderCard from '../components/OrderCard'
import { RestaurantContext } from '../constants/contexts'
import { listenToOrdersByRestaurantId } from '../database/reads/orders'
import { listenToTablesByRestaurantId } from '../database/reads/tables'
import { Order, Table } from '../types'

export default function Orders() {
  const [tables, setTables] = useState<Table[]>([])
  const [orders, setOrders] = useState<Order[]>([])

  const restaurant = useContext(RestaurantContext)

  useEffect(() => {
    if (!restaurant) {
      return
    }
    return listenToTablesByRestaurantId({
      restaurantId: restaurant.id,
      cb: setTables,
    })
  }, [restaurant])

  useEffect(() => {
    if (!restaurant) {
      return
    }
    return listenToOrdersByRestaurantId({
      restaurantId: restaurant.id,
      cb: setOrders,
    })
  }, [restaurant])

  return (
    <Flex flexDir='row' h='100vh' bg='background.50'>
      <NavBar />
      <HStack m={8} spacing={4} flex={1} align='stretch'>
        <Card flex={1} overflow='auto' rounded='3xl' bg='shade.white'>
          <Stack>
            <Stack p={4} spacing={4}>
              <Heading size='md'>New</Heading>
              <Accordion
                allowMultiple
                index={Array.from(Array(orders.length).keys())}
              >
                {orders.map((order: Order) => {
                  if (order.status !== 'New') return null
                  const table = tables.find(
                    (table) => table.id === order.tableId
                  )
                  if (!table) return null
                  return (
                    <OrderCard key={order.id} order={order} table={table} />
                  )
                })}
              </Accordion>
            </Stack>
          </Stack>
        </Card>
        <Card flex={1} overflow='auto' rounded='3xl' bg='shade.white'>
          <Stack>
            <Stack p={4} spacing={4}>
              <Heading size='md'>In Progress</Heading>
              <Accordion
                allowMultiple
                index={Array.from(Array(orders.length).keys())}
              >
                {orders.map((order: Order) => {
                  if (order.status !== 'In Progress') return null
                  const table = tables.find(
                    (table) => table.id === order.tableId
                  )
                  if (!table) return null
                  return (
                    <OrderCard key={order.id} order={order} table={table} />
                  )
                })}
              </Accordion>
            </Stack>
          </Stack>
        </Card>
        <Stack spacing={4} flex={1}>
          <Card flex={1} overflow='auto' rounded='3xl' bg='shade.white'>
            <Stack>
              <Stack p={4} spacing={4}>
                <Heading size='md'>Served</Heading>
                <Accordion
                  allowMultiple
                  index={Array.from(Array(orders.length).keys())}
                >
                  {orders.map((order: Order) => {
                    if (order.status !== 'Served') return null
                    const table = tables.find(
                      (table) => table.id === order.tableId
                    )
                    if (!table) return null
                    return (
                      <OrderCard key={order.id} order={order} table={table} />
                    )
                  })}
                </Accordion>
              </Stack>
            </Stack>
          </Card>
          <Card flex={1} overflow='auto' rounded='3xl' bg='shade.white'>
            <Stack>
              <Stack p={4} spacing={4}>
                <Heading size='md'>Paid</Heading>
                <Accordion
                  allowMultiple
                  index={Array.from(Array(orders.length).keys())}
                >
                  {orders.map((order: Order) => {
                    if (order.status !== 'Paid') return null
                    const table = tables.find(
                      (table) => table.id === order.tableId
                    )
                    if (!table) return null
                    return (
                      <OrderCard key={order.id} order={order} table={table} />
                    )
                  })}
                </Accordion>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </HStack>
    </Flex>
  )
}
