import { onAuthStateChanged } from 'firebase/auth'
import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { auth } from '../../constants/firebase'

export default function RequireAuth({ children }: { children: ReactNode }) {
  const navigate = useNavigate()

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => {
      if (!authUser) navigate('/login')
    })
  })

  return <>{children}</>
}
