import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { auth, db } from '../../constants/firebase'
import { AdminUser } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToUserById({
  userId,
  cb,
}: {
  userId: string
  cb: Function
}) {
  const userRef = doc(db, 'Users', userId)
  return onSnapshot(
    userRef.withConverter(genericConverter<AdminUser>()),
    (userSnapshot) => {
      if (userSnapshot.exists()) cb(userSnapshot.data())
      else createUser()
    },
    (err) => console.error(err)
  )
}

export async function createUser() {
  const authUser = auth.currentUser
  if (!authUser || !authUser.email) return
  const userRef = doc(db, 'Users', authUser.uid).withConverter(
    genericConverter<AdminUser>()
  )
  try {
    return setDoc(
      userRef,
      {
        email: authUser.email,
        restaurantIds: [],
      },
      { merge: true }
    )
  } catch (err) {
    console.error(err)
  }
}

export async function getUserByEmail({ email }: { email: string }) {
  const usersRef = collection(db, 'Users')
  try {
    const snapshot = await getDocs(
      query(usersRef, where('email', '==', email)).withConverter(
        genericConverter<AdminUser>()
      )
    )
    if (snapshot.docs.length === 0) return undefined
    return snapshot.docs[0].data()
  } catch (err) {
    console.error(err)
  }
}
