import {
  Box,
  HStack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'
import { DishOrder } from '../../types'
import { revenueTotal } from '../../utils/analytics'
import { simplifyNumber } from '../../utils/money'

export default function Statistic({
  orders1,
  orders2,
  term
}: {
  orders1: DishOrder[]
  orders2: DishOrder[]
  term: string
}) {
  return (
    <HStack justify='center' align='center' w='100%' h='100%'>
      <StatGroup>
        <Stat>
          <StatLabel>{term} Orders</StatLabel>
          <StatNumber>{orders1.length > 0 ? simplifyNumber(orders1.length) : '--'}</StatNumber>
          <StatHelpText whiteSpace='nowrap'>
            <StatArrow
              type={orders1.length >= orders2.length ? 'increase' : 'decrease'}
            />
            {orders1.length > 0 && orders2.length > 0
              ? (Math.abs(orders1.length / orders2.length - 1) * 100).toFixed(2)
              : '--'}
            %
          </StatHelpText>
        </Stat>
        <Box width={6} />
        <Stat>
          <StatLabel>Revenue</StatLabel>
          <StatNumber>
            ${orders1.length > 0 ? simplifyNumber(revenueTotal(orders1)/100) : '--'}
          </StatNumber>
          <StatHelpText whiteSpace='nowrap'>
            <StatArrow
              type={
                revenueTotal(orders1) >= revenueTotal(orders2)
                  ? 'increase'
                  : 'decrease'
              }
            />
            {orders1.length > 0 && orders2.length > 0
              ? (
                  Math.abs(revenueTotal(orders1) / revenueTotal(orders2) - 1) *
                  100
                ).toFixed(2)
              : '--'}
            %
          </StatHelpText>
        </Stat>
      </StatGroup>
    </HStack>
  )
}
