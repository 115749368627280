import { uuidv4 } from '@firebase/util'
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage'
import Resizer from 'react-image-file-resizer'
import { storage } from '../../constants/firebase'

function resizeImage(image: File, size: number) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      size,
      size,
      'PNG',
      100,
      0,
      (uri) => resolve(uri as File),
      'file'
    )
  }) as Promise<File>
}

export async function uploadImage({
  restaurantId,
  image,
  id,
}: {
  restaurantId: string
  image: File
  id: string
}) {
  const imageId = id ?? uuidv4()
  const storageRef = ref(storage, `${restaurantId}/${id}`)
  const compressedImage = await resizeImage(image, id === 'logo' ? 1080 : 480)
  await uploadBytes(storageRef, compressedImage)
  return imageId
}

export async function getImageURL({
  restaurantId,
  imageId,
}: {
  restaurantId: string
  imageId: string
}) {
  const storageRef = ref(storage, `${restaurantId}/${imageId}`)
  return await getDownloadURL(storageRef)
}

export async function deleteImage({
  restaurantId,
  imageId,
}: {
  restaurantId: string
  imageId: string
}) {
  const storageRef = ref(storage, `${restaurantId}/${imageId}`)
  return await deleteObject(storageRef)
}
