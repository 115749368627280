import { collection, doc, getDoc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Modification } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToModByRestaurantId({
  restaurantId,
  cb,
}: {
  restaurantId: string
  cb: Function
}) {
  const modRef = collection(db, 'Restaurants', restaurantId, 'Modifications')
  return onSnapshot(
    query(modRef, orderBy('title')).withConverter(
      genericConverter<Modification>()
    ),
    (restaurantSnapshot) => {
      cb(restaurantSnapshot.docs.map((doc) => doc.data()))
    }
  )
}

export async function getModItemById({
  restaurantId,
  itemId,
}: {
  restaurantId: string
  itemId: string
}) {
  const itemRef = doc(
    db,
    'Restaurants',
    restaurantId,
    'Modifications',
    itemId
  ).withConverter(genericConverter<Modification>())
  return getDoc(itemRef).then((doc) => doc.data())
}
