import { Badge } from '@chakra-ui/react'

export default function StatusBadge({ status }: { status: string }) {
  return (
    <>
      {status === 'New' && <Badge colorScheme='red'>New</Badge>}
      {status === 'In Progress' && (
        <Badge colorScheme='yellow'>In Progress</Badge>
      )}
      {status === 'Served' && <Badge colorScheme='green'>Served</Badge>}
      {status === 'Paid' && <Badge>Paid</Badge>}
    </>
  )
}
