import { useEffect, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DayRevenue, DishOrder } from '../../types';
import { revenueOnDay } from '../../utils/analytics';

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; 

    return (
      <div style={{ background: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{data.day}</div>
        <div style={{ marginBottom: '5px' }}>T1 Revenue: ${data.revenue1}.00</div>
        <div style={{ marginBottom: '5px' }}>T2 Revenue: ${data.revenue2}.00</div>
      </div>
    );
  }

  return null;
};

export default function TermComparison({orders1, orders2} : {orders1: DishOrder[], orders2: DishOrder[]}) {
  const [data, setData] = useState<DayRevenue[] | null>(null);

  useEffect(() => {
    const processedData = revenueOnDay(orders1, orders2);
    setData(processedData);
  }, [orders1, orders2]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      {data != null && data.length > 0 ? (
        <BarChart
          width={500}
          height={300}
          data={data}
        >
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="revenue1" fill="#e74536" />
          <Bar dataKey="revenue2" fill="#6bbaec" />
        </BarChart>
      ) : (
        <>
        </>
      )}
    </ResponsiveContainer>
  )
}
