import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { deleteModification } from '../../database/writes/modifications'
import { Modification, Restaurant } from '../../types'
import ModModal from './ModModal'

export default function ModCard({
  item,
  restaurant,
  onlySelect,
  selectFunction,
  isChecked,
}: {
  item: Modification
  restaurant: Restaurant
  onlySelect: boolean
  selectFunction: (item: Modification) => void
  isChecked: boolean
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false)

  const restaurantId = restaurant.id
  const { id, title, type, modifications, selectNumber, required } = item
  return (
    <>
      <Card
        key={id}
        direction='row'
        justify='space-between'
        align='center'
        overflow='hidden'
        variant='outline'
        gap={4}
        p={4}
      >
        <CardBody py={0}>
          <Flex justify='space-between'>
            <HStack>
              <Heading size='md' py={2}>
                {title}
              </Heading>
              {required && <Badge colorScheme='red'>Required</Badge>}
            </HStack>
            {onlySelect ? (
              <Checkbox
                onChange={() => selectFunction(item)}
                isChecked={isChecked}
              />
            ) : (
              <></>
            )}
          </Flex>
          <Text>
            Type: {type} |{' '}
            {type === 'quantity' ? <>Number of Choices</> : <>Max Quantity</>}:{' '}
            {selectNumber}
          </Text>
          <Text>
            {modifications.map((mod, i) => {
              if (i === modifications.length - 1) {
                return mod.name
              }
              return mod.name + ', '
            })}
          </Text>
        </CardBody>
        {!onlySelect ? (
          <CardFooter>
            <ButtonGroup spacing='2'>
              <Button
                onClick={onOpen}
                variant='solid'
                bg='brand.400'
                color='shade.white'
                _hover={{
                  bg: 'brand.600',
                }}
              >
                Edit
              </Button>

              <Button
                variant='ghost'
                colorScheme='background'
                isLoading={isDeleteBtnLoading}
                onClick={() => {
                  setIsDeleteBtnLoading(true)
                  deleteModification({
                    restaurantId,
                    modificationId: id,
                  }).finally(() => setIsDeleteBtnLoading(false))
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </CardFooter>
        ) : (
          <></>
        )}
      </Card>
      <ModModal item={item} isOpen={isOpen} onClose={onClose} />
    </>
  )
}
