import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../constants/firebase'

export function updateOrderStatus({
  restaurantId,
  tableId,
  orderId,
  status,
}: {
  restaurantId: string
  tableId: string
  orderId: string
  status: string
}) {
  const orderRef = doc(
    db,
    'Restaurants',
    restaurantId,
    'Tables',
    tableId,
    'Orders',
    orderId
  )
  try {
    return updateDoc(orderRef, { status })
  } catch (err) {
    console.error(err)
  }
}

export function deleteOrder({
  restaurantId,
  tableId,
  orderId,
}: {
  restaurantId: string
  tableId: string
  orderId: string
}) {
  const orderRef = doc(
    db,
    'Restaurants',
    restaurantId,
    'Tables',
    tableId,
    'Orders',
    orderId
  )
  try {
    return deleteDoc(orderRef)
  } catch (err) {
    console.error(err)
  }
}
