import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'

interface WithId {
  id?: string
}

export const genericConverter = <T extends WithId>() =>
  ({
    toFirestore(data: Partial<T>) {
      if (data?.id) delete data.id
      return data
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
      return { id: snapshot.id, ...snapshot.data() } as unknown as T
    },
  } as FirestoreDataConverter<T>)
