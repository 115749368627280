import {
  Box,
  Card,
  Flex,
  Heading,
  HStack,
  Image,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useContext } from 'react'
import EmployeesCard from '../components/edit/EmployeesCard'
import InformationCard from '../components/edit/InformationCard'
import MenuCard from '../components/edit/MenuCard'
import QRCodesCard from '../components/edit/QRCodesCard'
import NavBar from '../components/NavBar'
import { RestaurantContext } from '../constants/contexts'

export default function Edit() {
  const restaurant = useContext(RestaurantContext)

  return (
    <Flex flexDir='row' h='100vh' bg='background.50'>
      <NavBar />
      <Flex flex={1} justifyContent='center'>
        <Box overflow='auto' w='80%'>
          <Flex flexDirection='column' pt={16} pb={8}>
            <HStack>
              <Image
                alt={restaurant?.name}
                src={restaurant?.logo}
                boxSize={20}
                borderRadius='full'
                objectFit='contain'
                border='2px solid white'
                bg='shade.white'
                mr={6}
              />
              <Stack justifyContent='center' spacing={0}>
                <Heading size='md'>{restaurant?.name}</Heading>
                <Text>Your restaurant</Text>
              </Stack>
            </HStack>
            <Tabs orientation='vertical' w='100%'>
              <HStack align='start' w='100%'>
                <TabList mr={6} mt={6} border={0}>
                  <Tab
                    px={8}
                    _selected={{
                      bg: 'gray.200',
                      color: 'black',
                      borderLeft: '4px solid',
                      borderColor: 'brand.500',
                      fontWeight: 'bold',
                    }}
                  >
                    Information
                  </Tab>
                  <Tab
                    px={8}
                    _selected={{
                      bg: 'gray.200',
                      color: 'black',
                      borderLeft: '4px solid',
                      borderColor: 'brand.500',
                      fontWeight: 'bold',
                    }}
                  >
                    QR Codes
                  </Tab>
                  <Tab
                    px={8}
                    _selected={{
                      bg: 'gray.200',
                      color: 'black',
                      borderLeft: '4px solid',
                      borderColor: 'brand.500',
                      fontWeight: 'bold',
                    }}
                  >
                    Menu
                  </Tab>
                  <Tab
                    px={8}
                    _selected={{
                      bg: 'gray.200',
                      color: 'black',
                      borderLeft: '4px solid',
                      borderColor: 'brand.500',
                      fontWeight: 'bold',
                    }}
                  >
                    Employees
                  </Tab>
                </TabList>

                <TabPanels as={Card} rounded='3xl' bg='shade.white' flex={1}>
                  <TabPanel>
                    <Box>
                      {restaurant ? (
                        <InformationCard restaurant={restaurant} />
                      ) : (
                        <Spinner size='lg' alignSelf='center' />
                      )}
                    </Box>
                  </TabPanel>

                  <TabPanel>
                    <Box>
                      {restaurant ? (
                        <QRCodesCard restaurant={restaurant} />
                      ) : (
                        <Spinner size='lg' alignSelf='center' />
                      )}
                    </Box>
                  </TabPanel>

                  <TabPanel>
                    <Box>
                      {restaurant ? (
                        <MenuCard restaurant={restaurant} />
                      ) : (
                        <Spinner size='lg' alignSelf='center' />
                      )}
                    </Box>
                  </TabPanel>

                  <TabPanel>
                    <Box>
                      {restaurant ? (
                        <EmployeesCard restaurant={restaurant} />
                      ) : (
                        <Spinner size='lg' alignSelf='center' />
                      )}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </HStack>
            </Tabs>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}
