import { Flex, Heading, Spinner, Stack, Text } from '@chakra-ui/react'
import { ReactNode, useContext } from 'react'
import { RestaurantContext } from '../../constants/contexts'

const SUBSCRIPTION_BASE = 'base'
const SUBSCRIPTION_ANALYTICS = 'analytics'

function NoSubscription() {
  return (
    <Stack justify='center' align='center' minH='100vh'>
      <Heading as='h1'>Oops!</Heading>
      <Text>Sorry, your subscription does not contain this feature...</Text>
      <Heading as='h2' size='sm'>
        Please contact <a href='mailto: info@danqr.com'>info@danqr.com</a> to
        begin or upgrade your subscription.
      </Heading>
    </Stack>
  )
}

export function RequireSubscriptionBase({ children }: { children: ReactNode }) {
  const restaurant = useContext(RestaurantContext)

  if (!restaurant) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }
  if (!restaurant.subscriptions.includes(SUBSCRIPTION_BASE))
    return <NoSubscription />

  return <>{children}</>
}

export function RequireSubscriptionAnalytics({
  children,
}: {
  children: ReactNode
}) {
  const restaurant = useContext(RestaurantContext)

  if (!restaurant) return <Spinner />
  if (!restaurant.subscriptions.includes(SUBSCRIPTION_ANALYTICS))
    return <NoSubscription />

  return <>{children}</>
}
