import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Modification, ModOption } from '../../types'

export async function addModification({
  restaurantId,
  title,
  type,
  modifications,
  selectNumber,
  required
}: {
  restaurantId: string
  title: string
  type: string
  modifications: ModOption[]
  selectNumber: number
  required: boolean
}) {
  const modRef = collection(db, 'Restaurants', restaurantId, 'Modifications')
  const docRef = await addDoc(modRef, {
    title,
    type,
    modifications,
    selectNumber,
    required
  })
  return docRef
}

export async function deleteModification({
  restaurantId,
  modificationId,
}: {
  restaurantId: string
  modificationId: string
}) {
  const modItemRef = doc(db, 'Restaurants', restaurantId, 'Modifications', modificationId)
  return deleteDoc(modItemRef)
}

interface UpdateModItem extends Partial<Modification> {
  restaurantId: string
  modificationId: string
}
export async function updateModification({
  restaurantId,
  modificationId,
  ...updateFields
}: UpdateModItem) {
  const modificationRef = doc(db, 'Restaurants', restaurantId, 'Modifications', modificationId)
  return updateDoc(modificationRef, updateFields)
}