import { onAuthStateChanged } from 'firebase/auth'
import { ReactNode, useEffect, useState } from 'react'
import {
  MenuContext,
  ModContext,
  RestaurantContext,
  UserContext,
} from '../../constants/contexts'
import { auth } from '../../constants/firebase'
import { listenToMenuByRestaurantId } from '../../database/reads/menu'
import { listenToModByRestaurantId } from '../../database/reads/modifications'
import { listenToRestaurantById } from '../../database/reads/restaurants'
import { listenToUserById } from '../../database/reads/users'
import { AdminUser, MenuItem, Modification, Restaurant } from '../../types'

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [userId, setUserId] = useState('')
  const [user, setUser] = useState<AdminUser>()
  const [restaurant, setRestaurant] = useState<Restaurant>()
  const [menu, setMenu] = useState<MenuItem[]>([])
  const [mod, setMod] = useState<Modification[]>([])

  const restaurantId = sessionStorage.getItem('restaurantId')

  onAuthStateChanged(auth, (authUser) => {
    setUserId(authUser ? authUser.uid : '')
  })

  useEffect(() => {
    if (!userId) return
    return listenToUserById({ userId, cb: setUser })
  }, [userId])

  useEffect(() => {
    if (!restaurantId) return
    return listenToRestaurantById({ restaurantId, cb: setRestaurant })
  }, [restaurantId])

  useEffect(() => {
    if (!restaurantId) return
    return listenToMenuByRestaurantId({
      restaurantId,
      cb: setMenu,
    })
  }, [restaurantId])

  useEffect(() => {
    if (!restaurant) return
    return listenToModByRestaurantId({
      restaurantId: restaurant?.id,
      cb: setMod,
    })
  }, [restaurant])

  return (
    <UserContext.Provider value={user}>
      <RestaurantContext.Provider value={restaurant}>
        <MenuContext.Provider value={menu}>
          <ModContext.Provider value={mod}>{children}</ModContext.Provider>
        </MenuContext.Provider>
      </RestaurantContext.Provider>
    </UserContext.Provider>
  )
}
