import { Button, Icon, IconButton, Stack } from '@chakra-ui/react'
import {
  AiOutlineBarChart,
  AiOutlineContainer,
  AiOutlineHome,
  AiOutlineSetting,
} from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router'

const directories = [
  {
    name: 'Dashboard',
    path: '/',
    icon: <Icon as={AiOutlineHome} boxSize={6} />,
  },
  {
    name: 'Orders',
    path: '/orders',
    icon: <Icon as={AiOutlineContainer} boxSize={6} />,
  },
  {
    name: 'Analytics',
    path: '/analytics',
    icon: <Icon as={AiOutlineBarChart} boxSize={6} />,
  },
  {
    name: 'Settings',
    path: '/edit',
    icon: <Icon as={AiOutlineSetting} boxSize={6} />,
  },
]

export default function MenuStack({ isExpanded }: { isExpanded: boolean }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Stack>
      {directories.map(({ name, path, icon }) => {
        const isCurrent =
          path === '/' ? pathname === path : pathname.includes(path)
        return isExpanded ? (
          <Button
            size='md'
            key={name}
            leftIcon={icon}
            aria-label={name}
            variant={isCurrent ? 'solid' : 'ghost'}
            bg={isCurrent ? 'brand.200' : undefined}
            _hover={{ bg: 'brand.200' }}
            color='shade.white'
            onClick={() => {
              navigate(path)
            }}
            w='100%'
            justifyContent='start'
          >
            {name}
          </Button>
        ) : (
          <IconButton
            size='lg'
            key={name}
            icon={icon}
            aria-label={name}
            variant={isCurrent ? 'solid' : 'ghost'}
            bg={isCurrent ? 'brand.200' : undefined}
            _hover={{ bg: 'brand.200' }}
            color='shade.white'
            onClick={() => {
              navigate(path)
            }}
          />
        )
      })}
    </Stack>
  )
}
