import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../constants/firebase'

export function addUserRestaurant({
  restaurantId,
  userId,
}: {
  restaurantId: string
  userId: string
}) {
  const userRef = doc(db, 'Users', userId)
  try {
    return updateDoc(userRef, {
      restaurantIds: arrayUnion(restaurantId),
    })
  } catch (err) {
    console.error(err)
  }
}

export function removeUserRestaurant({
  restaurantId,
  userId,
}: {
  restaurantId: string
  userId: string
}) {
  const userRef = doc(db, 'Users', userId)
  try {
    return updateDoc(userRef, {
      restaurantIds: arrayRemove(restaurantId),
    })
  } catch (err) {
    console.error(err)
  }
}
