import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Restaurant } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToRestaurantById({
  restaurantId,
  cb,
}: {
  restaurantId: string
  cb: Function
}) {
  const restaurantRef = doc(db, 'Restaurants', restaurantId)
  return onSnapshot(
    restaurantRef.withConverter(genericConverter<Restaurant>()),
    (restaurantSnapshot) => cb(restaurantSnapshot.data()),
    (err) => console.error(err)
  )
}
