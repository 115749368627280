import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore'
import { isUndefined } from 'lodash'
import { db } from '../../constants/firebase'
import { MenuItem } from '../../types'
import { getImageURL, uploadImage } from '../storage/images'

export async function addMenuItem({
  restaurantId,
  name,
  picture,
  description,
  basePrice,
  category,
  modifications,
  tax,
}: {
  restaurantId: string
  name: string
  picture?: File
  description: string
  basePrice: number
  category: string
  modifications: string[]
  tax?: number
}) {
  const menuRef = collection(db, 'Restaurants', restaurantId, 'Menu')
  try {
    const docRef = await addDoc(menuRef, {
      name,
      description,
      basePrice,
      category,
      modifications,
      ...(!isUndefined(tax) && { tax }),
    })
    if (!picture) return
    const imageId = await uploadImage({
      restaurantId,
      image: picture,
      id: docRef.id,
    })
    const imageURL = await getImageURL({
      restaurantId,
      imageId,
    })
    return updateDoc(docRef, {
      image: imageURL,
    })
  } catch (err) {
    console.error(err)
  }
}

export async function deleteMenuItem({
  restaurantId,
  menuItemId,
}: {
  restaurantId: string
  menuItemId: string
}) {
  const menuItemRef = doc(db, 'Restaurants', restaurantId, 'Menu', menuItemId)
  try {
    return deleteDoc(menuItemRef)
  } catch (err) {
    console.error(err)
  }
}

interface UpdateMenuItem extends Partial<MenuItem> {
  restaurantId: string
  menuItemId: string
}
export async function updateMenuItem({
  restaurantId,
  menuItemId,
  ...updateFields
}: UpdateMenuItem) {
  const menuItemRef = doc(db, 'Restaurants', restaurantId, 'Menu', menuItemId)
  try {
    return updateDoc(menuItemRef, updateFields)
  } catch (err) {
    console.error(err)
  }
}
