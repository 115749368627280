import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'

export default function TermSelectModal({
  isOpen,
  onClose,
  setTerm,
}: {
  isOpen: boolean
  onClose: () => void
  setTerm: (start: Date, end: Date) => void
}) {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const toast = useToast()

  const handleConfirm = () => {
    if (!startDate || !endDate) {
      toast({
        title: 'Please fill in both fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else if (startDate > endDate) {
      toast({
        title: 'Start date cannot be after end date!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } else {
      setTerm(startDate, endDate)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Term</ModalHeader>
        <ModalBody>
          <FormControl gap={2}>
            <FormLabel>Start Time</FormLabel>
            <Input
              placeholder='Select Start Date'
              size='md'
              type='date'
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
            <FormLabel>End Time</FormLabel>
            <Input
              placeholder='Select End Date'
              size='md'
              type='date'
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            bg='brand.400'
            color='shade.white'
            _hover={{
              bg: 'brand.600',
            }}
            mr={3}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
          <Button variant='ghost' colorScheme='background' onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
