import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  useDisclosure,
} from '@chakra-ui/react'
import { useRef } from 'react'

interface ConfirmActionButtonProps
  extends Partial<ButtonProps>,
    Partial<IconButtonProps> {
  title: string
  action: string
  handleConfirm: any
  isIcon?: boolean
  description?: string
}
export default function ConfirmActionButton({
  title,
  action,
  handleConfirm,
  description,
  isIcon,
  ...btnProps
}: ConfirmActionButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef: any = useRef()

  return (
    <>
      {isIcon ? (
        <IconButton {...btnProps} onClick={onOpen} aria-label={title} />
      ) : (
        <Button {...btnProps} onClick={onOpen}>
          {title}
        </Button>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {description ??
                "Are you sure? You can't undo this action afterwards."}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme='background'
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                bg='brand.400'
                color='shade.white'
                _hover={{
                  bg: 'brand.600',
                }}
                onClick={handleConfirm}
                ml={3}
              >
                {action}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
