import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import AuthProvider from './components/auth/AuthProvider'
import RequireAuth from './components/auth/RequireAuth'
import RequireEnter from './components/auth/RequireEnter'
import {
  RequireSubscriptionAnalytics,
  RequireSubscriptionBase,
} from './components/auth/RequireSubscriptions'
import './index.css'
import Analytics from './pages/Analytics'
import Edit from './pages/Edit'
import Enter from './pages/Enter'
import Error from './pages/Error'
import Home from './pages/Home'
import Login from './pages/Login'
import Orders from './pages/Orders'

const theme = extendTheme({
  colors: {
    shade: {
      white: '#FFFFFF',
      light: '#BCBCBC',
      normal: '#5F5F5F',
      dark: '#2B2B2B',
    },
    brand: {
      50: '#FCEAE8',
      100: '#F7C4BF',
      200: '#F29E96',
      300: '#EE786D',
      400: '#E95244',
      500: '#E42C1B',
      600: '#B62316',
      700: '#891B10',
      800: '#5B120B',
      900: '#2E0905',
    },
    background: {
      50: '#EFF2F5',
      100: '#D3DAE3',
      200: '#B7C2D2',
      300: '#9BAAC0',
      400: '#7F92AE',
      500: '#637B9C',
      600: '#4F627D',
      700: '#3B4A5E',
      800: '#27313F',
      900: '#14191F',
    },
  },
})

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <RequireEnter>
          <RequireSubscriptionBase>
            <Home />
          </RequireSubscriptionBase>
        </RequireEnter>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'enter',
    element: (
      <RequireAuth>
        <Enter />
      </RequireAuth>
    ),
  },
  {
    path: 'edit',
    element: (
      <RequireAuth>
        <RequireEnter>
          <RequireSubscriptionBase>
            <Edit />
          </RequireSubscriptionBase>
        </RequireEnter>
      </RequireAuth>
    ),
  },
  {
    path: 'analytics',
    element: (
      <RequireAuth>
        <RequireEnter>
          <RequireSubscriptionBase>
            <RequireSubscriptionAnalytics>
              <Analytics />
            </RequireSubscriptionAnalytics>
          </RequireSubscriptionBase>
        </RequireEnter>
      </RequireAuth>
    ),
  },
  {
    path: 'orders',
    element: (
      <RequireAuth>
        <RequireEnter>
          <RequireSubscriptionBase>
            <Orders />
          </RequireSubscriptionBase>
        </RequireEnter>
      </RequireAuth>
    ),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      {/* Contains restaurant and menu contexts */}
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
)
