import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router'

export default function RequireEnter({ children }: { children: ReactNode }) {
  const navigate = useNavigate()

  const restaurantId = sessionStorage.getItem('restaurantId')

  useEffect(() => {
    if (!restaurantId) navigate('/enter')
  }, [navigate, restaurantId])

  return <>{children}</>
}
