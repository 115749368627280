import { toInteger } from 'lodash'

export function toCents(value: number) {
  return toInteger(value * 100)
}

export function toDollar(value: number) {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value / 100)
}

export function simplifyNumber(value: number): string {
  const abbreviations = ['', 'k', 'M', 'B'];

  let index = 0;
  while (value >= 1000 && index < abbreviations.length - 1) {
    value /= 1000;
    index++;
  }

  const roundedValue = Math.round(value * 10) / 10;
  const formattedValue = roundedValue === Math.floor(roundedValue) ? Math.floor(roundedValue) : roundedValue;

  return formattedValue + abbreviations[index];
}
