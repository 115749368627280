import { DocumentReference, Timestamp } from 'firebase/firestore'

export interface Restaurant {
  id: string
  name: string
  address: Address
  logo: string
  isPlainMenu: Boolean
  hasPaid: Boolean
  tax: number // in percentage
  subscriptions: string[]
}

export enum AdminStatus {
  admin = 'admin',
  manager = 'manager',
  employee = 'employee',
}

export interface Employee {
  id: string
  status: AdminStatus
  isInvited: boolean
  email: string
}

export interface Employments {
  restaurant: Restaurant
  employee: Employee
}

export interface Table {
  id: string
  tableNumber: number
}

export interface Order {
  id: string
  tableId: string
  restaurantId: string
  status: string
  phoneNumber: string
  dateTime: Timestamp
  subtotal: number
  tax: number
}

export interface Dish {
  id: string
  itemId: string
  quantity: number
  note: string
  price: number
  modifications: DishModification[]
  tax?: number // in percentage
}

export interface DishModification {
  title: string
  type: string
  modifications: DishModItem[]
}

export interface DishModItem {
  id: string
  name: string
  basePrice: number
  quantity: number
}

export interface MenuItem {
  id: string
  name: string
  image?: string
  description: string
  basePrice: number
  category: string
  modifications: string[]
  tax?: number // in percentage
}

export interface Modification {
  id: string
  title: string
  type: string
  modifications: ModOption[]
  selectNumber: number
  required: boolean
}

export interface ModOption {
  name: string
  basePrice: number
}

export interface Address {
  line1: string
  line2?: string
  city: string
  province: string
  country: string
  postalCode: string
}

export interface AdminUser {
  id: string
  email: string
  restaurantIds: string[]
}

export interface ClientUser {
  id: string
  phoneNumber: string
}

export interface PastOrder {
  id: string // restaurantId
  orders: DocumentReference[]
}

export interface DishOrder {
  id: string
  tableId: string
  restaurantId: string
  status: string
  phoneNumber: string
  dateTime: Timestamp
  subtotal: number
  tax: number
  dishes: NameDish[]
}

export interface NameDish {
  id: string
  itemId: string
  quantity: number
  note: string
  price: number
  modifications: DishModification[]
  name: string
  category: string
}

export interface RankedMenuItem {
  itemId: string
  name: string | undefined
  quantity: number
  percentage: number
}

export interface RankedCategory {
  name: string
  quantity: number
  percentage: number
}

export interface DayRevenue {
  day: string
  revenue1: number
  revenue2: number
}

export interface DateRevenue {
  date: string
  revenue: number
}
