import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { MenuItem } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToMenuByRestaurantId({
  restaurantId,
  cb,
}: {
  restaurantId: string
  cb: Function
}) {
  const menuRef = collection(db, 'Restaurants', restaurantId, 'Menu')
  return onSnapshot(
    query(menuRef, orderBy('category')).withConverter(
      genericConverter<MenuItem>()
    ),
    (restaurantSnapshot) => {
      cb(restaurantSnapshot.docs.map((doc) => doc.data()))
    },
    (err) => console.error(err)
  )
}

export async function getMenuItemById({
  restaurantId,
  itemId,
}: {
  restaurantId: string
  itemId: string
}) {
  const itemRef = doc(
    db,
    'Restaurants',
    restaurantId,
    'Menu',
    itemId
  ).withConverter(genericConverter<MenuItem>())
  try {
    return getDoc(itemRef).then((doc) => doc.data())
  } catch (err) {
    console.error(err)
  }
}
