import { DishOrder, RankedCategory, RankedMenuItem } from '../types'

export function rankMenuItems(orders: DishOrder[]) {
  const itemMap: { [itemId: string]: RankedMenuItem } = {}

  // Count the number of times each itemId appears
  orders.forEach((order) => {
    order.dishes.forEach((dish) => {
      const itemId = dish.itemId
      const quantity = dish.quantity
      const name = dish.name

      if (itemMap[itemId]) {
        itemMap[itemId].quantity += quantity
      } else {
        itemMap[itemId] = { itemId, name, quantity, percentage: 0 }
      }
    })
  })

  const totalItems = Object.values(itemMap).reduce(
    (total, item) => total + item.quantity,
    0
  )

  const result = Object.values(itemMap).map((item) => {
    const { itemId, name, quantity } = item
    const percentage = quantity / totalItems

    return { itemId, name, quantity, percentage }
  })

  result.sort((a, b) => b.quantity - a.quantity)

  return result
}

export function rankCategories(orders: DishOrder[]) {
  const categoryMap: { [category: string]: RankedCategory } = {}

  // Count the number of times each category appears
  orders.forEach((order) => {
    order.dishes.forEach((dish) => {
      const name = dish.category
      const quantity = dish.quantity

      if (categoryMap[name]) {
        categoryMap[name].quantity += quantity
      } else {
        categoryMap[name] = { name, quantity, percentage: 0 }
      }
    })
  })

  const totalItems = Object.values(categoryMap).reduce(
    (total, category) => total + category.quantity,
    0
  )

  const result = Object.values(categoryMap).map((category) => {
    const { name, quantity } = category
    const percentage = quantity / totalItems

    return { name, quantity, percentage }
  })

  result.sort((a, b) => b.quantity - a.quantity)

  return result
}

export function revenueTotal(orders: DishOrder[]) {
  return orders.reduce((prev, curr) => prev + curr.subtotal, 0)
}

export function revenueOnDay(orders1: DishOrder[], orders2: DishOrder[]) {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const revenuePerDay = Array(7).fill(0).map(() => [0, 0])

  orders1.forEach((order: DishOrder) => {
    const orderDate = order.dateTime.toDate()

    revenuePerDay[orderDate.getDay()][0] += order.subtotal
  })

  orders2.forEach((order: DishOrder) => {
    const orderDate = order.dateTime.toDate()

    revenuePerDay[orderDate.getDay()][1] += order.subtotal
  })
  console.log(revenuePerDay)
  const output = revenuePerDay.map((revenue, index) => ({
    day: daysOfWeek[index].substring(0, 3),
    revenue1: revenue[0] / 100,
    revenue2: revenue[1] / 100
  }))

  console.log(output)

  return output
}

export function revenueOnDate(orders: DishOrder[]) {
  const revenuePerDate: { [date: string]: number } = {}

  orders.forEach((order: DishOrder) => {
    const orderDate = order.dateTime.toDate()
    const dateKey = orderDate.toDateString()

    if (!revenuePerDate[dateKey]) {
      revenuePerDate[dateKey] = order.subtotal
    }

    revenuePerDate[dateKey] += order.subtotal
  })

  const output = Object.entries(revenuePerDate).map(([date, revenue]) => ({
    date,
    revenue: revenue / 100,
  }))

  output.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  return output
}
