import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Table } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToTablesByRestaurantId({
  restaurantId,
  cb,
}: {
  restaurantId: string
  cb: Function
}) {
  const tableRef = collection(db, 'Restaurants', restaurantId, 'Tables')
  return onSnapshot(
    query(tableRef, orderBy('tableNumber', 'asc')).withConverter(
      genericConverter<Table>()
    ),
    (tableSnapshot) => {
      cb(tableSnapshot.docs.map((doc) => doc.data()))
    },
    (err) => console.error(err)
  )
}
