import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { RestaurantContext } from '../../constants/contexts'
import {
  addModification,
  updateModification,
} from '../../database/writes/modifications'
import { Modification, ModOption } from '../../types'
import { toCents } from '../../utils/money'

export default function ModModal({
  item,
  isOpen,
  onClose,
}: {
  item?: Modification
  isOpen: boolean
  onClose: () => void
}) {
  const toast = useToast()
  const restaurant = useContext(RestaurantContext)

  const [isBtnLoading, setIsBtnLoading] = useState(false)

  const [title, setTitle] = useState(item?.title ?? '')
  const [type, setType] = useState(item?.type ?? 'radio')
  const [modifications, setModifications] = useState<ModOption[]>(
    item?.modifications ?? [{ name: '', basePrice: 0 }]
  )
  const [selectNumber, setSelectNumber] = useState<number>(
    item?.selectNumber ?? 1
  )
  const [required, setRequired] = useState<boolean>(item?.required ?? false)

  const onSubmit = async () => {
    setIsBtnLoading(true)
    if (!restaurant) return setIsBtnLoading(false)
    if (!title || modifications.length === 0) {
      toast({
        title: 'Please fill in all required fields',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
      return setIsBtnLoading(false)
    }

    const allModificationsFilled = modifications.every((modification) => {
      return modification.name !== ''
    })

    if (!allModificationsFilled) {
      toast({
        title: 'Please fill in all options or delete the unfilled option(s)',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
      return setIsBtnLoading(false)
    }

    if (item) {
      const fields: Partial<Modification> = {
        title,
        type,
        modifications,
        selectNumber,
        required,
      }
      await updateModification({
        restaurantId: restaurant.id,
        modificationId: item.id,
        ...fields,
      })
    } else {
      await addModification({
        restaurantId: restaurant.id,
        title,
        type,
        modifications,
        selectNumber,
        required,
      })
    }
    setIsBtnLoading(false)
    toast({
      title: !item
        ? 'Successfully created modification'
        : 'Successfully updated modification',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    })

    onClose()

    // reset
    if (!item) {
      setTitle('')
      setType('radio')
      setModifications([{ name: '', basePrice: 0 }])
      setSelectNumber(1)
      setRequired(false)
    }
  }

  const handleCancel = () => {
    onClose()

    if (!item) {
      setTitle('')
      setType('radio')
      setModifications([{ name: '', basePrice: 0 }])
      setSelectNumber(1)
      setRequired(false)
    } else {
      setTitle(item.title)
      setType(item.type)
      setModifications(item.modifications)
      setSelectNumber(item.selectNumber)
      setRequired(item.required)
    }
  }

  const handleAddModOption = () => {
    const newModOption: ModOption = { name: '', basePrice: 0 }
    setModifications([...modifications, newModOption])
  }

  const handleDeleteModOption = (index: number) => {
    setModifications((prevModifications) => {
      const updatedModifications = [...prevModifications]
      updatedModifications.splice(index, 1)
      return updatedModifications
    })
  }

  const handleNameChange = (index: number, value: string) => {
    const newModifications = [...modifications]
    newModifications[index] = {
      name: value,
      basePrice: newModifications[index].basePrice,
    }
    setModifications(newModifications)
  }

  const handleBasePriceChange = (index: number, value: number) => {
    const newModifications = [...modifications]
    newModifications[index] = {
      name: newModifications[index].name,
      basePrice: value,
    }
    setModifications(newModifications)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item ? 'Edit' : 'Create'} Mod Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant='soft-rounded'>
            <TabList>
              <Tab>Details</Tab>
              <Tab>Options</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <FormControl
                  display='grid'
                  gridTemplateColumns='1fr 2fr'
                  alignItems='center'
                  gap={2}
                >
                  <FormLabel>Title</FormLabel>
                  <Input
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    placeholder='eg. Premium toppings, Sugar level, etc.'
                  />
                  <FormLabel>Type</FormLabel>
                  <Select
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value)
                      if (e.target.value === 'radio') {
                        setSelectNumber(1)
                      } else if (
                        e.target.value === 'checkbox' &&
                        selectNumber > modifications.length
                      ) {
                        setSelectNumber(modifications.length)
                      }
                    }}
                  >
                    <option value='radio'>Radio</option>
                    <option value='checkbox'>Checkbox</option>
                    <option value='quantity'>Quantity</option>
                  </Select>
                  <FormLabel>
                    {type === 'checkbox' || type === 'radio' ? (
                      <>Max choices</>
                    ) : (
                      <>Max quantity</>
                    )}
                  </FormLabel>
                  <NumberInput
                    value={selectNumber}
                    onChange={(_, value) => {
                      setSelectNumber(value)
                    }}
                    min={1}
                    max={type === 'checkbox' ? modifications.length : 100}
                    isDisabled={type === 'radio'}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormLabel>Required?</FormLabel>
                  <Switch
                    onChange={() => setRequired(!required)}
                    isFocusable={false}
                    isChecked={required}
                  />
                </FormControl>
              </TabPanel>
              <TabPanel>
                <Button onClick={handleAddModOption} mb={2}>
                  Add Option
                </Button>
                <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                  <GridItem>
                    <FormLabel>Name</FormLabel>
                  </GridItem>
                  <GridItem>
                    <FormLabel>Price</FormLabel>
                  </GridItem>
                  {modifications.map((modification, index) => (
                    <React.Fragment key={index}>
                      <GridItem>
                        <Input
                          value={modification.name}
                          onChange={(event) =>
                            handleNameChange(index, event.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem>
                        <HStack>
                          <NumberInput
                            value={modification.basePrice / 100}
                            onChange={(_, value) =>
                              handleBasePriceChange(index, toCents(value))
                            }
                            min={0}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                          <IconButton
                            aria-label='Delete option'
                            onClick={() => handleDeleteModOption(index)}
                            icon={<DeleteIcon />}
                            isDisabled={modifications.length <= 1}
                          />
                        </HStack>
                      </GridItem>
                    </React.Fragment>
                  ))}
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            bg='brand.400'
            color='shade.white'
            _hover={{
              bg: 'brand.600',
            }}
            mr={3}
            onClick={onSubmit}
            isLoading={isBtnLoading}
          >
            {item ? 'Confirm' : 'Create'}
          </Button>
          <Button variant='ghost' onClick={handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
