import { AttachmentIcon, ChevronDownIcon, EditIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { isUndefined, uniq } from 'lodash'
import { useContext, useState } from 'react'
import { MenuContext, ModContext } from '../../constants/contexts'
import { deleteImage } from '../../database/storage/images'
import { deleteMenuItem } from '../../database/writes/menu'
import { MenuItem as MenuItemType, Restaurant } from '../../types'
import { toDollar } from '../../utils/money'
import MenuCSVModal from './MenuCSVModal'
import MenuItemModal from './MenuItemModal'
import ModCard from './ModCard'
import ModModal from './ModModal'

function MenuItemCard({
  item,
  restaurant,
}: {
  item: MenuItemType
  restaurant: Restaurant
}) {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const restaurantId = restaurant.id
  const { id, name, image, description, basePrice } = item
  return (
    <>
      <Card
        key={id}
        direction='row'
        justify='space-between'
        align='center'
        overflow='hidden'
        variant='outline'
        gap={4}
        p={4}
      >
        <Stack>
          <CardBody py={0}>
            <Heading size='md' py={2}>
              {name}
            </Heading>
            <Text>
              {toDollar(basePrice)}{' '}
              {!isUndefined(item.tax) && ` (${item.tax}% tax)`}
            </Text>

            <Text opacity={0.9}>{description}</Text>
          </CardBody>

          <CardFooter>
            <ButtonGroup spacing='2'>
              <Button
                onClick={onOpen}
                variant='solid'
                bg='brand.400'
                color='shade.white'
                _hover={{
                  bg: 'brand.600',
                }}
              >
                Edit
              </Button>

              <Button
                variant='ghost'
                colorScheme='background'
                onClick={async () => {
                  setIsDeleteLoading(true)
                  if (item.image)
                    await deleteImage({ restaurantId, imageId: id })
                  await deleteMenuItem({ restaurantId, menuItemId: id })
                  setIsDeleteLoading(false)
                }}
                isLoading={isDeleteLoading}
              >
                Delete
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Stack>
        {image && (
          <Image
            objectFit='cover'
            boxSize={36}
            rounded={4}
            src={image}
            alt={`Image for ${name}`}
          />
        )}
      </Card>
      <MenuItemModal item={item} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default function MenuCard({ restaurant }: { restaurant: Restaurant }) {
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure()
  const {
    isOpen: isModCreateOpen,
    onOpen: onModCreateOpen,
    onClose: onModCreateClose,
  } = useDisclosure()
  const {
    isOpen: isCSVOpen,
    onOpen: onCSVOpen,
    onClose: onCSVClose,
  } = useDisclosure()
  const menu = useContext(MenuContext)
  const mods = useContext(ModContext)
  const categories = uniq(menu.map((menuItem) => menuItem.category))
  return (
    <>
      <Card variant='ghost' w='100%'>
        <CardHeader pt='0'>
          <Flex justify='space-between' align='center'>
            <Heading as='h2' size='lg' my={2}>
              Menu
            </Heading>
            <Menu>
              <MenuButton
                aria-label='Add new menu item(s)'
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem icon={<EditIcon />} onClick={onCreateOpen}>
                  Create item
                </MenuItem>
                <MenuItem icon={<EditIcon />} onClick={onModCreateOpen}>
                  Create modification option
                </MenuItem>
                <MenuItem icon={<AttachmentIcon />} onClick={onCSVOpen}>
                  Upload CSV
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </CardHeader>
        <Divider />
        <CardBody>
          <Tabs variant='soft-rounded' colorScheme='brand' gap={8}>
            <TabList>
              <Flex justify='space-between' w='100%'>
                <Flex>
                  <Tab px={4}>All</Tab>
                  {categories.map((category: string) => (
                    <Tab key={category} px={4}>
                      {category}
                    </Tab>
                  ))}
                </Flex>
                <Tab px={4}>Modifications</Tab>
              </Flex>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Stack>
                  {menu.map((item) => (
                    <MenuItemCard
                      item={item}
                      restaurant={restaurant}
                      key={item.id}
                    />
                  ))}
                </Stack>
              </TabPanel>
              {categories.map((category: string) => (
                <TabPanel key={category}>
                  <Stack>
                    {menu
                      .filter((menuItem) => menuItem.category === category)
                      .map((item) => (
                        <MenuItemCard
                          item={item}
                          restaurant={restaurant}
                          key={item.id}
                        />
                      ))}
                  </Stack>
                </TabPanel>
              ))}
              <TabPanel>
                <Stack>
                  {mods.map((item) => (
                    <ModCard
                      item={item}
                      restaurant={restaurant}
                      key={item.id}
                      onlySelect={false}
                      isChecked={false}
                      selectFunction={() => {}}
                    />
                  ))}
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
      <MenuCSVModal isOpen={isCSVOpen} onClose={onCSVClose} />
      <MenuItemModal isOpen={isCreateOpen} onClose={onCreateClose} />
      <ModModal isOpen={isModCreateOpen} onClose={onModCreateClose} />
    </>
  )
}
