import { collection, onSnapshot, query } from 'firebase/firestore'
import { db } from '../../constants/firebase'
import { Dish } from '../../types'
import { genericConverter } from '../../utils/firestore'

export function listenToDishesByOrderId({
  restaurantId,
  tableId,
  orderId,
  cb,
}: {
  restaurantId: string
  tableId: string
  orderId: string
  cb: Function
}) {
  const dishesRef = collection(
    db,
    'Restaurants',
    restaurantId,
    'Tables',
    tableId,
    'Orders',
    orderId,
    'Dishes'
  )
  return onSnapshot(
    query(dishesRef).withConverter(genericConverter<Dish>()),
    (dishesSnapshot) => {
      cb(dishesSnapshot.docs.map((doc) => doc.data()))
    },
    (err) => console.error(err)
  )
}
